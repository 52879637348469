/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RoleDto = {
    id: string;
    name: RoleDto.name;
};
export namespace RoleDto {
    export enum name {
        WORKER = 'WORKER',
        DIRECTOR = 'DIRECTOR',
        ACCOUNTANT = 'ACCOUNTANT',
        ADMIN = 'ADMIN',
    }
}

