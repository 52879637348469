import { createApi } from '@reduxjs/toolkit/query/react';
import httpRequestHandler from 'shared/functions/baseQueryHandler.function';
import { CreateProjectDto, CreateTaskDto, CreateUserDto, CreateWorkPackageDto, PageRequestDto, PageResponseDtoListProjectDto, ProjectDto, SuccessDto, PageResponseDtoListUserDto, UpdateProjectWorkersDto, ProjectFilterParams, UpdateTaskWorkersDto, FreeDayDto, FreeDayParamDto, CreateFreeDayDto, ClockingDto, CreateClockingDto, ClockingFilterParams, UserMonthlyClockingsDto } from 'shared/generated-sources';
import { UpdateProjectDto } from 'shared/generated-sources/models/UpdateProjectDto';
import { UpdateTaskDto } from 'shared/generated-sources/models/UpdateTaskDto';

export const projectsApi = createApi({
    reducerPath: 'projectsApi',
    baseQuery: httpRequestHandler,
    tagTypes: ["Task", "Clocking", "Project"],
    endpoints: (builder) => (
        {
            getProjects: builder.query<PageResponseDtoListProjectDto, { pageRequest: PageRequestDto, filterParams: ProjectFilterParams }>({
                query: ({ pageRequest, filterParams }) => {

                    const queryString = new URLSearchParams({
                        pageNumber: String(pageRequest.pageNumber),
                        pageSize: String(pageRequest.pageSize),
                        sortBy: pageRequest.sortBy || '',
                        sortDirection: pageRequest.sortDirection || '',
                        director: filterParams.director !== undefined ? String(filterParams.director) : '',
                        worker: filterParams.worker !== undefined ? String(filterParams.worker) : '',
                    }).toString();

                    return `/projects?${queryString}`;
                },
            }),

            getProject: builder.query<ProjectDto, { id: string }>({
                query: (props) => `/projects/${props.id}`,
                providesTags: (result, error, { id }) => [{ type: 'Task', id }],

            }),

            getUsers: builder.query<PageResponseDtoListUserDto, { pageRequest: PageRequestDto, notInProject?: string }>({
                query: ({ pageRequest, notInProject }) => (
                    {
                        url: `/users`,
                        params: {
                            ...pageRequest,
                            notInProject
                        }
                    }
                )
            }),

            getFreeDays: builder.query<FreeDayDto[], { freeDayParams: FreeDayParamDto }>({
                query: ({ freeDayParams }) => {
                    const queryString = new URLSearchParams({
                        year: freeDayParams.year.toString(),
                        month: freeDayParams.month.toString(),
                    }).toString();

                    return `/freeDays?${queryString}`
                }

            }),

            addFreeDay: builder.mutation({
                query: (date: CreateFreeDayDto) => (
                    {
                        url: '/freeDays',
                        method: 'POST',
                        body: date
                    }),
            }),

            deleteFreeDay: builder.mutation<FreeDayDto, { freeDayId: string }>({
                query: ({ freeDayId }) => (
                    {
                        url: `/freeDays/${freeDayId}`,
                        method: 'DELETE'
                    }),
            }),

            getClocking: builder.query<ClockingDto[], { clockingParams: ClockingFilterParams }>({
                query: ({ clockingParams }) => {

                    const queryString = new URLSearchParams({
                        projectIds: clockingParams.projectIds?.join(",") || '',
                        workPackageIds: clockingParams.workPackageIds?.join(",") || '',
                        taskIds: clockingParams.taskIds?.join(",") || '',
                        projectMonthId: clockingParams.projectMonthId || '',
                        workerIds: clockingParams.workerIds?.join(",") || '',
                        startDate: clockingParams.startDate || '',
                        endDate: clockingParams.endDate || '',
                        monthFilter: clockingParams.monthFilter?.toString() || '',
                    }).toString();
                    return `/users/clockings?${queryString}`;
                },
                providesTags: ["Clocking"],
            }),

            addClockings: builder.mutation<ClockingDto[], { projectId: string, workPackageId: string, taskId: string, clockings: CreateClockingDto[] }>({
                query: ({ projectId, workPackageId, taskId, clockings }) => {
                    return {
                        url: `/projects/${projectId}/workPackages/${workPackageId}/tasks/${taskId}/clockings`,
                        method: 'POST',
                        body: clockings
                    };
                },
                invalidatesTags: ["Clocking", "Task"]
            }),

            addMonthlyClockings: builder.mutation<UserMonthlyClockingsDto[], { projectId: string, userMonthlyClockings: UserMonthlyClockingsDto[] }>({
                query: ({ projectId, userMonthlyClockings }) => {
                    return {
                        url: `/projects/${projectId}/distributeMonthlyClockings`,
                        method: 'POST',
                        body: userMonthlyClockings
                    };
                },
                invalidatesTags: ["Clocking", "Task"]
            }),

            addProject: builder.mutation({
                query: (project: CreateProjectDto) => (
                    {
                        url: '/projects',
                        method: 'POST',
                        body: project
                    }),
            }),

            updateProject: builder.mutation<ProjectDto, { projectId: string, updatedProjectDto: UpdateProjectDto }>({
                query: ({ projectId, updatedProjectDto }) => (
                    {
                        url: `/projects/${projectId}`,
                        method: 'PUT',
                        body: updatedProjectDto
                    }),
            }),

            deleteProject: builder.mutation<SuccessDto, { projectId: string }>({
                query: ({ projectId }) => (
                    {
                        url: `/projects/${projectId}`,
                        method: 'DELETE'
                    }),
            }),

            addUser: builder.mutation({
                query: (user: CreateUserDto) => (
                    {
                        url: '/users',
                        method: 'POST',
                        body: user
                    }
                )
            }),

            addWorkPackage: builder.mutation<ProjectDto, { projectId: string, workPackage: CreateWorkPackageDto }>({
                query: ({ projectId, workPackage }) => (
                    {
                        url: `/projects/${projectId}/workPackages`,
                        method: 'POST',
                        body: workPackage
                    }),
            }),

            updateWorkPackage: builder.mutation<ProjectDto, { projectId: string, workPackageId: string, workPackage: CreateWorkPackageDto }>({
                query: ({ projectId, workPackageId, workPackage }) => (
                    {
                        url: `/projects/${projectId}/workPackages/${workPackageId}`,
                        method: 'PUT',
                        body: workPackage
                    }),
            }),

            deleteWorkPackage: builder.mutation<ProjectDto, { projectId: string, workPackageId: string }>({
                query: ({ projectId, workPackageId }) => (
                    {
                        url: `/projects/${projectId}/workpackages/${workPackageId}`,
                        method: 'DELETE'
                    }),
            }),

            addTask: builder.mutation<ProjectDto, { projectId: string, workPackageId: string, task: CreateTaskDto }>({
                query: ({ projectId, workPackageId, task }) => (
                    {
                        url: `/projects/${projectId}/workPackages/${workPackageId}/tasks`,
                        method: 'POST',
                        body: task
                    }),
                invalidatesTags: (result, error, { projectId }) => [{ type: 'Task', id: projectId }],

            }),

            updateTask: builder.mutation<ProjectDto, { projectId: string, workPackageId: string, taskId: string, task: UpdateTaskDto }>({
                query: ({ projectId, workPackageId, taskId, task }) => ({
                    url: `/projects/${projectId}/workPackages/${workPackageId}/tasks/${taskId}`,
                    method: "PUT",
                    body: task
                }),
                invalidatesTags: (result, error, { projectId }) => [{ type: 'Task', id: projectId }],

            }),

            deleteTask: builder.mutation<ProjectDto, { projectId: string, workPackageId: string, taskId: string }>({
                query: ({ projectId, workPackageId, taskId }) => (
                    {
                        url: `/projects/${projectId}/workpackages/${workPackageId}/tasks/${taskId}`,
                        method: 'DELETE'
                    }),
                invalidatesTags: (result, error, { projectId }) => [{ type: 'Task', id: projectId }],

            }),

            addWorkersToProject: builder.mutation<ProjectDto, { projectId: string, usersIds: UpdateProjectWorkersDto }>({
                query: ({ projectId, usersIds }) => (
                    {
                        url: `/projects/${projectId}/workers`,
                        method: 'POST',
                        body: usersIds
                    }),
                invalidatesTags: (result, error, { projectId }) => [{ type: 'Task', id: projectId }],

            }),

            deleteWorkersFromProject: builder.mutation<ProjectDto, { projectId: string, usersIds: UpdateProjectWorkersDto }>({
                query: ({ projectId, usersIds }) => (
                    {
                        url: `/projects/${projectId}/workers`,
                        method: 'DELETE',
                        body: usersIds
                    }),
                invalidatesTags: (result, error, { projectId }) => [{ type: 'Task', id: projectId }],

            }),

            addWorkersToTask: builder.mutation<ProjectDto, { projectId: string, workPackageId: string, taskId: string, usersIds: UpdateTaskWorkersDto }>({
                query: ({ projectId, workPackageId, taskId, usersIds }) => (
                    {
                        url: `/projects/${projectId}/workpackages/${workPackageId}/tasks/${taskId}/workers`,
                        method: 'POST',
                        body: usersIds
                    }),
                invalidatesTags: (result, error, { projectId }) => [{ type: 'Task', id: projectId }],
            }),

            deleteWorkersFromTask: builder.mutation<ProjectDto, { projectId: string, workPackageId: string, taskId: string, usersIds: UpdateTaskWorkersDto }>({
                query: ({ projectId, workPackageId, taskId, usersIds }) => (
                    {
                        url: `/projects/${projectId}/workpackages/${workPackageId}/tasks/${taskId}/workers`,
                        method: 'DELETE',
                        body: usersIds
                    }),
                invalidatesTags: (result, error, { projectId }) => [{ type: 'Task', id: projectId }],
            }),
        }
    )
});

export const {
    useGetFreeDaysQuery, useLazyGetClockingQuery, useAddMonthlyClockingsMutation, useGetClockingQuery, useGetProjectsQuery, useGetProjectQuery, useGetUsersQuery, useAddProjectMutation, useDeleteWorkersFromProjectMutation, useAddUserMutation, useAddWorkersToProjectMutation, useAddWorkersToTaskMutation, useDeleteWorkersFromTaskMutation, useUpdateProjectMutation,
    useAddFreeDayMutation, useDeleteFreeDayMutation, useAddClockingsMutation, useAddWorkPackageMutation, useAddTaskMutation, useUpdateWorkPackageMutation, useUpdateTaskMutation, useDeleteProjectMutation, useDeleteWorkPackageMutation, useDeleteTaskMutation
} = projectsApi;
