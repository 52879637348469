import React from 'react';
import { Form, Input, Select } from 'antd';

import { CreateUserDto, RoleDto } from 'shared/generated-sources';
import { enumToOptions } from 'shared/constants/enumFunctions';

import ModalComponent from 'components/ModalComponent';

interface AddUsersToPlatformModalProps {
    userRole: string;
    onSubmit: (values: CreateUserDto) => void;
    onClose: () => void;
}

const AddUsersToPlatformModal: React.FC<AddUsersToPlatformModalProps> = ({ userRole, onSubmit, onClose }) => {

    const getAvailableRoles = () => {
        switch (userRole) {
            case RoleDto.name.ADMIN:
                return ['WORKER', 'DIRECTOR', 'ACCOUNTANT', 'ADMINISTRATOR'];
            case RoleDto.name.ACCOUNTANT:
                return ['WORKER', 'DIRECTOR'];
            case RoleDto.name.DIRECTOR:
                return ['WORKER'];
            default:
                return [];
        }
    };

    const roleOptions = enumToOptions({
        enumObj: RoleDto.name,
        allowedValues: getAvailableRoles(),
    });

    return (<ModalComponent<CreateUserDto>
        open
        name="Invite user"
        onClose={onClose}
        onSubmit={onSubmit}>
        <Form.Item
            name="firstName"
            label="FirstName"
            rules={[{ required: true, message: 'Please input a first name' }]}
        >
            <Input type={"string"} />
        </Form.Item>
        <Form.Item
            name="lastName"
            label="LastName"
            rules={[{ required: true, message: 'Please input a last name' }]}
        >
            <Input type={"string"} />
        </Form.Item>
        <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input an email' }]}
        >
            <Input type={"string"} />
        </Form.Item>
        <Form.Item
            name="externalWorkHours"
            label="External work hours"
            rules={[{ required: true, message: 'Please input external work hours' }]}
        >
            <Input type={"number"} />
        </Form.Item>
        
        <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: 'Please choose a role' }]}>
            <Select options={roleOptions} />

        </Form.Item>
    </ModalComponent>
    );
};

export default AddUsersToPlatformModal;