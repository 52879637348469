import {Layout} from "antd";

import HeroComponent from "components/HeroComponent";


const HomePage = () => {
    return (
            <Layout style={layoutStyle}>
                <HeroComponent/>
            </Layout>
    )
}

export default HomePage;


const layoutStyle = {
    borderRadius: 8,
    overflow: 'hidden'
};
