import React from 'react';
import { Form, Input } from 'antd';

import { CreateProjectDto } from 'shared/generated-sources';

import ModalComponent from 'components/ModalComponent';

interface AddProjectModalProps {
    onSubmit: (values: CreateProjectDto) => void;
    onClose: () => void;
}

const AddProjectModal: React.FC<AddProjectModalProps> = ({ onSubmit, onClose }) => {

    return (
        <ModalComponent<CreateProjectDto>
            open
            name="Add Project"
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item

                name="allocatedPersonMonths"
                label="Allocated PM"
                rules={[{ required: true, message: 'Choose PM amount', type: 'number', transform: (value) => (value ? Number(value) : value), }]}
                normalize={(value) => (value ? Number(value) : value)}
            >
                <Input type={"number"} />
            </Form.Item>
            <Form.Item
                name="startDate"
                label="Start date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"} />
            </Form.Item>
            <Form.Item
                name="endDate"
                label="End date"
                rules={[{ required: true, message: 'Choose a date', type: 'date' }]}
            >
                <Input type={"date"} />
            </Form.Item>

        </ModalComponent>
    );
};

export default AddProjectModal;