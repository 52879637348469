import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Button, Card, Dropdown, Flex, Spin, Tooltip } from "antd";
import { LoadingOutlined, UserAddOutlined, UserDeleteOutlined, TeamOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { MenuProps } from 'antd';
import dayjs from "dayjs";

import { getCurrentUserEmail } from "store/store";
import { useAddWorkPackageMutation, useGetProjectQuery, useUpdateWorkPackageMutation, useDeleteWorkPackageMutation, useAddWorkersToProjectMutation, useDeleteWorkersFromProjectMutation, useLazyGetClockingQuery } from "projects/api/ProjectApi";
import { CreateWorkPackageDto, ProjectDto, UpdateWorkPackageDto, UpdateProjectWorkersDto, WorkPackageDto, UserDto, ClockingDto, TaskDto } from "shared/generated-sources";
import { useModal } from "shared/contexts/ModalContext";

import AddWorkPackageModal from "components/modals/AddWorkPackageModal";
import UpdateWorkPackageModal from "components/modals/UpdateWorkPackageModal";
import TableComponent from "components/TableComponent";
import TasksComponent from "components/TasksComponent";

import AddWorkersOnProjectModal from "components/modals/AddWorkersOnProjectModal";
import RemoveWorkersFromProjectModal from "components/modals/RemoveWorkersFromProjectModal";

const ProjectPage = () => {
    const { showModal, hideModal } = useModal();

    const [project, setProject] = useState<ProjectDto | undefined>(undefined);
    const [clockingPerWorkPackage, setClockingPerWorkPackage] = useState<Record<string, number>>({});
    const [clockingPerTask, setClockingPerTask] = useState<Record<string, Record<string, number>>>({});
    const [clockingPerTaskByMonth, setClockingPerTaskByMonth] = useState<Record<string, Record<string, Record<string, number>>>>({});

    const [addWorkPackage] = useAddWorkPackageMutation();
    const [updateWorkPackage] = useUpdateWorkPackageMutation();
    const [deleteWorkPackage] = useDeleteWorkPackageMutation();

    const [addWorkers] = useAddWorkersToProjectMutation();

    const [removeWorkers] = useDeleteWorkersFromProjectMutation();
    const { id } = useParams();

    const { data, isLoading, isSuccess } = useGetProjectQuery({ id: id! }, { refetchOnMountOrArgChange: true });

    const userEmail = useSelector(getCurrentUserEmail);

    const [triggerGetClocking, { data: clockingData, isLoading: isLoadingClocking, isSuccess: isSuccessClocking }] = useLazyGetClockingQuery();

    useEffect(() => {
        if (isSuccess) {
            workpackageClocking(data);

            setProject(data);
        }
    }, [isSuccess, data, clockingData]);


    const findWorkPackageById=(workPackageId: string): WorkPackageDto | undefined =>{
        return project?.workPackages.find(workPackage => workPackage.id === workPackageId);
    }

    const handleAddWorkPackage = async (projectId: string, workPackage: CreateWorkPackageDto) => {

        const response = await addWorkPackage({ projectId: projectId, workPackage });
        setProject(response.data!);
    }

    const handleUpdateWorkPackage = async (workPackageId: string, workPackage: UpdateWorkPackageDto) => {
        const project = await updateWorkPackage({ projectId: id!, workPackageId, workPackage });
        setProject(project.data as ProjectDto);
    }

    const handleDeleteWorkPackage = async (record: { id: string }) => {
        const project = await deleteWorkPackage({ projectId: id!, workPackageId: record.id });
        setProject(project.data!);
    }

    const handleExpandWorkpackage = (record: { id: string, reference: number }) => {
        const workPackage = project?.workPackages.find(wp => wp.id === record.id);
        if (!workPackage) return null;

        const taskClockingData = {
            taskClockings: clockingPerTask[record.id] || {},
            taskClockingsByMonth: clockingPerTaskByMonth[record.id] || {}
        };

        return (
            <TasksComponent
                project={project}
                projectId={id!}
                workpackageId={record.id!}
                workpackageRef={record.reference}
                tasks={workPackage.tasks}
                clockingData={taskClockingData}
            />
        );
    };

    const handleAddWorkerToProject = async (usersIds: UpdateProjectWorkersDto) => {
        const project = await addWorkers({ projectId: id!, usersIds: usersIds })
        setProject(project.data)
    }

    const handleDeleteWorkerFromProject = async (usersIds: UpdateProjectWorkersDto) => {
        const project = await removeWorkers({ projectId: id!, usersIds: usersIds })
        setProject(project.data)
    }

    const openAddWorkersToProjectModal = () => {

        showModal(
            "addWorkersToProjectModal",
            <AddWorkersOnProjectModal
                onClose={() => hideModal("addWorkersToProjectModal")}
                onSubmit={(values) => {
                    handleAddWorkerToProject(values);
                    hideModal("addWorkersToProjectModal");
                }}
                projectId={project!.id}

            />
        );
    };

    const openDeleteWorkersToProjectModal = () => {

        showModal(
            "removeWorkersFromProjectModal",
            <RemoveWorkersFromProjectModal
                onClose={() => hideModal("removeWorkersFromProjectModal")}
                onSubmit={(values) => {
                    handleDeleteWorkerFromProject(values)
                    hideModal("removeWorkersFromProjectModal");
                }}
                workers={project!.workers}
            />

        );
    };

    const openAddWorkPackageModal = (projectId: string) => {

        showModal(
            "addWorkPackageModal",
            <AddWorkPackageModal
                onClose={() => hideModal("addWorkPackageModal")}
                onSubmit={(values) => {
                    handleAddWorkPackage(projectId, values).then(data => {
                        hideModal("addWorkPackageModal")
                    })
                }}
            />
        );
    };

    const openUpdateWorkPackageModal = (record: { id: string }) => {
        const currentWorkPackage = findWorkPackageById(record.id);

        showModal(
            "updateWorkPackageModal",
            <UpdateWorkPackageModal
                onClose={() => hideModal("updateWorkPackageModal")}
                onSubmit={(values) => {
                    handleUpdateWorkPackage(record.id, values).then(data => {
                        hideModal("updateWorkPackageModal")
                    })
                }}
                initialValues={{
                    name: currentWorkPackage ? currentWorkPackage.name : "",
                    reference: currentWorkPackage ? currentWorkPackage.reference : 0,
                    description: currentWorkPackage ? currentWorkPackage.description : "",
                    status: currentWorkPackage ? currentWorkPackage.status : UpdateWorkPackageDto.status.INACTIVE,
                    allocatedPersonMonths: currentWorkPackage ? currentWorkPackage.allocatedPersonMonths : 0,
                }}
            />
        );
    };

    const PROJECT_COLUMNS = [
        {
            title: 'Ref',
            dataIndex: 'reference',
            key: 'reference',
            onCell: () => ({
                style: { width: '5vw' }
            }),
            render: (text: string) => `WP${text}`,
        },
        {
            title: 'Clocked Hours',
            dataIndex: 'clockedHours',
            key: 'clockedHours',
            onCell: () => ({
                style: { width: '8vw' },
            }),
            render: (_: number, record: WorkPackageDto) => { return `${clockingPerWorkPackage[record.id] ? clockingPerWorkPackage[record.id] : 0}` },
        },
        {
            title: 'Person Months',
            dataIndex: 'allocatedPersonMonths',
            key: 'allocatedPersonMonths',
            onCell: () => ({
                style: { width: '10vw' },
            }),
            render: (dataIndex: number, record: WorkPackageDto) => `${record.consumedPersonMonth!.toFixed(3) || 0}/${record.allocatedPersonMonths || 0}`,
        },
        {

            title: 'Work packages',
            dataIndex: 'name',
            key: 'name',
            onCell: () => ({
                style: { width: '50vw' }
            }),
        },
    ]

    const items: MenuProps['items'] =
[            {
                key: '0',
                type: 'group',
                label: 'Director',
                children: [
                    {
                        label: project?.director.email || "No director",
                        key: project?.director.id || "No director",
                        className: 'nonHoverable',
                    },
                ]
            },
            {
                type: "divider"
            },
            {
                key: '1',
                type: 'group',
                label: 'Workers',
                children: [
                    ...(project?.workers || []).map((worker: UserDto) => ({
                        label: worker.email,
                        key: worker.id,
                        className: 'nonHoverable',
                    }))
                ]
            },
        ] 

    if (isLoading) {
        return <Spin indicator={<LoadingOutlined spin />} size="large" />
    }

    const workpackageClocking = async (project: ProjectDto) => {
        try {
            const { data } = await triggerGetClocking({
                clockingParams: {
                    workPackageIds: project.workPackages.map((workPackage: WorkPackageDto) => workPackage.id),
                },
            });

            if (data) {
                const newClockingPerWorkPackage: Record<string, number> = {};
                const newClockingPerTask: Record<string, Record<string, number>> = {};
                const newClockingPerTaskByMonth: Record<string, Record<string, Record<string, number>>> = {};

                data.forEach((clocking: ClockingDto) => {
                    if (clocking.workPackageId) {
                        newClockingPerWorkPackage[clocking.workPackageId] =
                            (newClockingPerWorkPackage[clocking.workPackageId] || 0) + clocking.hoursWorked;

                        if (clocking.taskId) {
                            if (!newClockingPerTask[clocking.workPackageId]) {
                                newClockingPerTask[clocking.workPackageId] = {};
                                newClockingPerTaskByMonth[clocking.workPackageId] = {};
                            }

                            newClockingPerTask[clocking.workPackageId][clocking.taskId] = 
                                (newClockingPerTask[clocking.workPackageId][clocking.taskId] || 0) + clocking.hoursWorked;

                            const monthKey = dayjs(clocking.date).format('YYYY-MM');
                            if (!newClockingPerTaskByMonth[clocking.workPackageId][clocking.taskId]) {
                                newClockingPerTaskByMonth[clocking.workPackageId][clocking.taskId] = {};
                            }
                            newClockingPerTaskByMonth[clocking.workPackageId][clocking.taskId][monthKey] = 
                                (newClockingPerTaskByMonth[clocking.workPackageId][clocking.taskId][monthKey] || 0) + clocking.hoursWorked;
                        }
                    }
                });

                setClockingPerWorkPackage(newClockingPerWorkPackage);
                setClockingPerTask(newClockingPerTask);
                setClockingPerTaskByMonth(newClockingPerTaskByMonth);
            }
        } catch (error) {
            console.error("Error fetching clocking data:", error);
        }
    }

    return (
        <>
            {project !== undefined ?
                <div>
                    <Card title={
                        <>
                            Project Name: {project!.name} <br />
                            Person Months: {project!.consumedPersonMonth?.toFixed(3)} / {project!.allocatedPersonMonths}
                        </>
                    } bordered={false} extra={
                        <Flex gap="large">
                            <Dropdown
                                menu={{
                                    items
                                }}

                                trigger={['hover']}
                            >
                                <Button type="primary">
                                        <TeamOutlined style={{ fontSize: '200%' }} />
                                </Button>
                            </Dropdown>
                            {userEmail === project.director.email &&
                                <>
                                    <Tooltip placement="bottom" title={"Add worker to project"}>
                                        <Button type="primary" onClick={openAddWorkersToProjectModal}>
                                            <UserAddOutlined style={{ fontSize: '200%' }} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip placement="bottom" title={"Remove worker from project"}>
                                        <Button type="primary" onClick={openDeleteWorkersToProjectModal}>
                                            <UserDeleteOutlined style={{ fontSize: '200%' }} />
                                        </Button>
                                    </Tooltip>
                                </>}
                        </Flex>
                    }>

                            <TableComponent
                                data={data ? project.workPackages : []}
                                columns={PROJECT_COLUMNS}
                                onAdd={userEmail === project?.director.email ? () => openAddWorkPackageModal(project.id) : undefined}
                                tooltip="workpackage"
                                onEdit={userEmail === project?.director.email ? openUpdateWorkPackageModal : undefined}
                                onDelete={userEmail === project?.director.email ? handleDeleteWorkPackage : undefined}
                                expandComponent={(record) => handleExpandWorkpackage(record)}
                            />
                        
                    </Card>
                </div>
                :
                <div>
                    <Card title="Project not found" bordered={false}>
                    </Card>
                </div>}
        </>
    )
}

export default ProjectPage;


const customStyles = `
    .nonHoverable {
        pointer-events: none; /* Makes it non-clickable */
        color: black !important; /* Ensures the text stays black */
        background-color: transparent !important; /* Keeps the background neutral */
        cursor: default !important; /* No pointer cursor */
    }
    .nonHoverable:hover {
        background-color: transparent !important; /* Remove hover background */
    }
`;

// Dynamically inject styles into the DOM
const styleTag = document.createElement("style");
styleTag.innerHTML = customStyles;
document.head.appendChild(styleTag);