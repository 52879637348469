import {Outlet, useLocation, useNavigate} from "react-router";
import {Layout, Menu, theme} from 'antd';

import {navigation} from "shared/constants/Routes";
import {RoutePathKeys} from "shared/constants/RoutePathKeys.enum";

import FooterComponent from "components/FooterComponent";

const {Header, Content} = Layout;

const PublicLayout = () => {
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();

    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuClick = ({key}: { key: string }) => {
        const {target} = navigation.public.find(item => item.key === key) || {};

        if (target) {
            navigate(target);
        }
    };

    return (
            <Layout style={{minHeight: "100vh"}}>
                <Header style={{display: 'flex', alignItems: 'center'}}>
                    <div className="demo-logo"/>
                    <Menu
                            theme="dark"
                            mode="horizontal"
                            selectedKeys={[location.pathname]}
                            defaultSelectedKeys={[RoutePathKeys.homepage]}
                            items={navigation.public}
                            onClick={handleMenuClick}
                            style={{flex: 1, minWidth: 0}}
                    />
                </Header>
                <Layout>
                    <Layout>
                        <Content
                                style={{
                                    padding: 0,
                                    margin: 0,
                                    background: colorBgContainer,
                                    borderRadius: borderRadiusLG,
                                }}
                        >
                            <Outlet/>
                        </Content>
                        <FooterComponent/>
                    </Layout>
                </Layout>
            </Layout>
    );
};

export default PublicLayout;
