    import React, {createContext, ReactNode, useCallback, useContext, useState} from 'react';

    interface ModalContextProps {
        showModal: (key: string, content: ReactNode) => void;
        hideModal: (key: string) => void;
        modals: Record<string, ReactNode>;
    }

    const ModalContext = createContext<ModalContextProps | undefined>(undefined);

    export const ModalProvider: React.FC<{ children: ReactNode }> = ({children}) => {
        const [modals, setModals] = useState<Record<string, ReactNode>>({});

        const showModal = useCallback((key: string, content: ReactNode) => {
            setModals(prevModals => ({...prevModals, [key]: content}));
        }, []);

        const hideModal = useCallback((key: string) => {
            setModals(prevModals => {
                const newModals = {...prevModals};
                delete newModals[key];
                return newModals;
            });
        }, []);

        return (
                <ModalContext.Provider value={{showModal, hideModal, modals}}>
                    {children}
                    {Object.entries(modals).map(([key, modalContent]) => (
                    <div key={key}>{modalContent}</div>
                ))}
                </ModalContext.Provider>
        );
    };

    export const useModal = (): ModalContextProps => {
        const context = useContext(ModalContext);
        if (!context) {
            throw new Error('useModal must be used within a ModalProvider');
        }
        return context;
    };
