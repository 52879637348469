import { useNavigate } from "react-router";
import { Button, Form, FormProps, Input, Typography } from "antd";

import { usePasswordResetRequestMutation } from "auth/api/AuthApi";
import { RequestResetPasswordDto } from "shared/generated-sources";


const PasswordResetRequestPage = () => {
    const [passwordResetRequest] = usePasswordResetRequestMutation();

    const navigate = useNavigate();

    const handlePasswordResetRequest: FormProps<RequestResetPasswordDto>['onFinish'] = async (value) => {
        const response = await passwordResetRequest(value);
        navigate("/login");
    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={handlePasswordResetRequest}
            autoComplete="off"
        >
            <Typography.Title level={3} style={{ textAlign: "center" }}>
                Send Password Reset Request
            </Typography.Title>
            <Form.Item<RequestResetPasswordDto>
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}
export default PasswordResetRequestPage;
