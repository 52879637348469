import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Dropdown, MenuProps, message } from 'antd';
import { ClockingDto, CreateClockingDto, FreeDayDto, FreeDayParamDto, TaskDto, UserDto } from 'shared/generated-sources';
import ModalComponent from 'components/ModalComponent';
import dayjs, { Dayjs } from 'dayjs';
import CalendarComponent from 'components/CalendarComponent';
import { useAddClockingsMutation, useGetFreeDaysQuery, useLazyGetClockingQuery } from 'projects/api/ProjectApi';

interface AddClockingModalProps {
  users: UserDto[];
  startDate: Dayjs;
  endDate: Dayjs;
  task: TaskDto | null;
  projectId: string;
  workPackageId: string;
  onSubmit: (values: CreateClockingDto[], date: string) => void;
  onClose: () => void;
}
interface FormValues {
  [key: string]: string | number;
}

const AddClockingModal: React.FC<AddClockingModalProps> = ({
  projectId,
  workPackageId,
  users,
  startDate,
  endDate,
  task,
  onSubmit,
  onClose,
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>();
  const [selectedDates, setSelectedDates] = useState<FreeDayDto[]>([]);
  const [currentDayTaskClocking, setCurrentDayTaskClocking] = useState<ClockingDto[]>([]);
  const [totalClocking, setTotalClocking] = useState<ClockingDto[]>([]);
  const [clockingCacheCurrent, setClockingCacheCurrent] = useState<{ [date: string]: ClockingDto[] }>({});
  const [clockingCacheTotal, setClockingCacheTotal] = useState<{ [date: string]: ClockingDto[] }>({});
  const [addClocking] = useAddClockingsMutation();
  const [triggerGetClocking] = useLazyGetClockingQuery();
  const freeDayParams: FreeDayParamDto = {
    month: startDate.month() + 1,
    year: startDate.year(),
  };
  const { data: freeDaysData, isSuccess: isSuccessFreeDays } = useGetFreeDaysQuery(
    { freeDayParams },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isSuccessFreeDays && freeDaysData) {
      setSelectedDates(freeDaysData);
    }
  }, [isSuccessFreeDays, freeDaysData]);

  const disabledDate = (current: Dayjs) => {
    if (current < startDate || current > endDate) return true;
    return selectedDates.some(selected =>
      dayjs(selected.date).isSame(current, 'day')
    );
  };

  const headerRender = ({ value }: { value: Dayjs; onChange: (date: Dayjs) => void }) => (
    <>
      <span>{value.format("YYYY-MMMM")}</span>
      <br />
      <span>Task: {task?.title}</span>
      <br />
      <br />
    </>
  );
  
  const dateCellRender = (value: Dayjs) => {
    const outsideDate = value < startDate || value > endDate;
    const isSelected = selectedDate?.isSame(value, 'day');
    const isDisabled = disabledDate(value);
    return (
      <div
        onClick={() => !outsideDate && handleDateSelect(value)}
        style={{
          backgroundColor: isSelected ? '#baece1' : 'white',
          color: isDisabled ? 'red' : '#00b197',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '32px',
          height: '32px',
          cursor: outsideDate ? 'default' : 'pointer',
          borderRadius: '50%',
          margin: 'auto',
          visibility: outsideDate ? 'hidden' : 'visible',
        }}
      >
        {!outsideDate ? value.date() : ''}
      </div>
    );
  };
  const handleDateSelect = (value: Dayjs) => {
    const formattedDate = value.format("YYYY-MM-DD");
    if (formattedDate !== selectedDate?.format("YYYY-MM-DD")) {
      setSelectedDate(value);
      getClockingData(formattedDate);
    }
  };
  const getClockingData = async (formattedDate: string, force: boolean = false) => {
    if (!force && clockingCacheCurrent[formattedDate] && clockingCacheTotal[formattedDate]) {
      setCurrentDayTaskClocking(clockingCacheCurrent[formattedDate]);
      setTotalClocking(clockingCacheTotal[formattedDate]);
    } else {
      try {
        const params = {
          clockingParams: {
            workerIds: users.map(user => user.id),
            startDate: formattedDate,
            endDate: formattedDate,
          },
        };
        const response = await triggerGetClocking(params);
        
        if (response.data) {
          const currentTaskClockings = response.data?.filter(clocking => 
            task ? clocking.taskId === task.id : false
          ) ?? [];
          
          setCurrentDayTaskClocking(currentTaskClockings);
          setTotalClocking(response.data ?? []);
          
          setClockingCacheCurrent(prev => ({ ...prev, [formattedDate]: currentTaskClockings }));
          setClockingCacheTotal(prev => ({ ...prev, [formattedDate]: response.data ?? [] }));
        } else {
          setCurrentDayTaskClocking([]);
          setTotalClocking([]);
        }
      } catch (error) {
        console.error("Error fetching clocking data:", error);
        setCurrentDayTaskClocking([]);
        setTotalClocking([]);
      }
    }
  };
  const handleSubmit = async (values: FormValues) => {
    if (!selectedDate) return;
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    const parsedInputs: CreateClockingDto[] = users.map(user => {
      const hoursWorked = parseFloat(values[`hoursWorked_${user.id}`] as string);
      const existingHours =
        currentDayTaskClocking.find((clocking: ClockingDto) => clocking.workerId === user.id)?.hoursWorked ?? 0;
      return {
        workerId: user.id,
        date: formattedDate,
        hoursWorked: !Number.isNaN(hoursWorked) ? hoursWorked : existingHours,
      };
    });
    
    try {
      // Attempt to add the clocking; if it fails (e.g. 400 error), execution jumps to catch
      await addClocking({ projectId, workPackageId, taskId: task ? task.id : "", clockings: parsedInputs }).unwrap();
      
      // Only update state if the API call succeeds.
      const updatedCurrentClockings = parsedInputs.map(input => ({
        ...input,
        taskId: task?.id,
      })) as ClockingDto[];
      
      const filteredTotalClockings = totalClocking.filter(clocking => 
        task ? clocking.taskId !== task.id : true
      );
      const updatedTotalClockings = [...filteredTotalClockings, ...updatedCurrentClockings];
      
      setCurrentDayTaskClocking(updatedCurrentClockings);
      setTotalClocking(updatedTotalClockings);
      setClockingCacheCurrent({});
      setClockingCacheTotal({});
      
      onSubmit(parsedInputs, formattedDate);
    } catch (error: any) {
      console.error("Error adding clocking:", error);
      
      // Show error message based on the error type
      if (error.status === 400) {
        message.error('Cannot add clocking: Total hours exceed daily limit');
      } else {
        message.error('Failed to add clocking. Please try again.');
      }
  
      // Refresh the data to ensure UI shows the correct state.
      if (selectedDate) {
        getClockingData(formattedDate, true);
      }
    }
  };
  
  const getClockingBreakdown = (): MenuProps['items'] => {
    return [];
  };
  const handleTotalHoursClicked = (userId: string) => {
  };
  return (
    <ModalComponent<FormValues>
      open
      onSubmit={handleSubmit}
      name="Add Clocking"
      onClose={onClose}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Form.Item
          name="calendar"
          rules={[
            {
              validator: (_, value) => {
                if (selectedDate) return Promise.resolve();
                return Promise.reject(new Error('Please select a date'));
              },
            },
          ]}
          style={{ marginBottom: '16px' }}
        >
          <CalendarComponent
            disabledDate={disabledDate}
            dateCellRender={dateCellRender}
            headerRender={headerRender}
            month={startDate}
          />
        </Form.Item>

        <Row 
          key="header" 
          justify="space-between" 
          style={{ 
            padding: '8px 16px',
            background: '#fafafa',
            borderRadius: '4px',
            fontWeight: 500,
            marginBottom: '8px'
          }}
        >
          <Col flex="1">Worker</Col>
          <Col flex="1" style={{ textAlign: 'center' }}>Total</Col>
          <Col flex="1" style={{ textAlign: 'center' }}>Current</Col>
          <Col flex="1" style={{ textAlign: 'center' }}>Update</Col>
        </Row>

        <div style={{ 
          overflowY: 'auto',
          height: '144px',
          paddingRight: '4px'
        }}>
          {users.map(user => (
            <Row 
              key={user.id} 
              justify="space-between" 
              align="middle"
              style={{ 
                height: '48px',
                padding: '0 8px',
                background: '#fff',
                borderRadius: '4px',
                boxShadow: '0 1px 2px rgba(0,0,0,0.05)',
                marginBottom: '8px'
              }}
            >
              <Col flex="1">
                <span style={{ fontWeight: 500 }}>{`${user.firstName} ${user.lastName}`}</span>
              </Col>
              <Dropdown
                menu={{ items: getClockingBreakdown() }}
                trigger={['click']}
                placement="topLeft"
              >
                <Col flex="1" onClick={() => handleTotalHoursClicked(user.id)} style={{ textAlign: 'center', cursor: 'pointer' }}>
                  <span>
                    {totalClocking.reduce((total, clocking) => {
                      if (clocking.workerId === user.id) return total + clocking.hoursWorked;
                      return total;
                    }, 0)}
                  </span>
                </Col>
              </Dropdown>
              <Col flex="1" style={{ textAlign: 'center' }}>
                <span>
                  {currentDayTaskClocking.find((clocking: ClockingDto) => clocking.workerId === user.id)?.hoursWorked ?? 0}
                </span>
              </Col>
              <Col flex="1" style={{ textAlign: 'center' }}>
                <Form.Item
                  name={`hoursWorked_${user.id}`}
                  style={{ 
                    margin: 0,
                    position: 'relative'
                  }}
                  rules={[
                    {
                      validator: (_, value) => {
                        const numberValue = Number(value);
                        if (!value || (numberValue >= 0 && numberValue <= 8)) return Promise.resolve();
                        return Promise.reject();
                      },
                    },
                  ]}
                >
                  <div style={{ position: 'relative' }}>
                    <Input 
                      type="number" 
                      placeholder="0-8" 
                      min={0} 
                      max={8}
                      style={{ 
                        width: '70px',
                        textAlign: 'center'
                      }} 
                    />
                    <div className="ant-form-item-explain-error"
                      style={{
                        position: 'absolute',
                        top: '100%',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        zIndex: 1
                      }} 
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </ModalComponent>
  );
};

export default AddClockingModal;
