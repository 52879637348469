import React from 'react';
import { Form, Select } from 'antd';
import { UpdateTaskWorkersDto, UserDto } from 'shared/generated-sources';
import ModalComponent from 'components/ModalComponent';

interface addWorkersOnTaskModalProps {
    onSubmit: (values: UpdateTaskWorkersDto) => void;
    onClose: () => void;
    users: UserDto[]
}

const AddWorkersOnTaskModal: React.FC<addWorkersOnTaskModalProps> = ({ onSubmit, onClose, users }) => {

    const userOptions = users.map((user) => ({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
    }));

    return (
        <ModalComponent<UpdateTaskWorkersDto>
            open
            onClose={onClose}
            onSubmit={onSubmit}
            name="Add Task Workers"
        >
            <Form.Item
                name="usersIds"
                label="Users"
                style={{minHeight:"300px"}}
                rules={[{ required: true, message: 'Search for workers' }]}
            >
                <Select
                    mode="tags"
                    style={{
                        width: '100%',
                    }}
                    dropdownRender={(menu) => (
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {menu}
                        </div>
                    )}
                    tokenSeparators={[',']}
                    options={userOptions}
                />
            </Form.Item>
        </ModalComponent>
    );
};

export default AddWorkersOnTaskModal;