import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import TableComponent from './TableComponent';
import { PageRequestDto, UserDto } from 'shared/generated-sources';
import { Wage_Roles } from 'shared/constants/enum';
import { useAddUserMutation, useGetUsersQuery } from 'projects/api/ProjectApi';

interface UserWageData extends UserDto {
  wageRole: keyof typeof Wage_Roles;
}

const { Option } = Select;

const UsersWageTableComponent: React.FC = () => {
  const [addUser] = useAddUserMutation();
  const [users, setUsers] = useState<UserWageData[]>([]);

  const { data, isLoading, isSuccess } = useGetUsersQuery(
    {
      pageRequest: {
        pageNumber: 0,
        pageSize: 10,
        sortBy: "firstName",
        sortDirection: PageRequestDto.sortDirection.ASC,
      },
    },
    { refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isSuccess && data) {
      const usersWithWage: UserWageData[] = data.data!.map(user => ({
        ...user,
        wageRole: 'Assistent',
      }));
      setUsers(usersWithWage);
    }
  }, [isSuccess, data]);

  const handleRoleChange = (id: string, newRole: keyof typeof Wage_Roles) => {
    setUsers(prev =>
      prev.map(item => (item.id === id ? { ...item, wageRole: newRole } : item))
    );
  };

  const columns: ColumnsType<UserWageData> = [
    {
      title: 'Name',
      key: 'name',
      render: (_: any, record: UserWageData) =>
        `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Wage Role',
      dataIndex: 'wageRole',
      key: 'wageRole',
      render: (_: any, record: UserWageData) => (
        <Select
          value={record.wageRole}
          style={{ width: 150 }}
          onChange={(value: keyof typeof Wage_Roles) => handleRoleChange(record.id, value)}
        >
          {Object.keys(Wage_Roles)
            .filter(key => isNaN(Number(key)))
            .map(role => (
              <Option key={role} value={role}>
                {role}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: 'Wage Value',
      key: 'wageValue',
      render: (_: any, record: UserWageData) => Wage_Roles[record.wageRole],
    },
  ];

  return (
    <TableComponent
      data={users}
      columns={columns}
      tooltip="User Wage"
    />
  );
};

export default UsersWageTableComponent;
