import { useModal } from 'shared/contexts/ModalContext';
import { UpdateTaskWorkersDto, UserDto, UpdateTaskDto, ProjectDto, TaskDto, CreateTaskDto } from 'shared/generated-sources';
import { useAddWorkersToTaskMutation, useDeleteWorkersFromTaskMutation, useUpdateTaskMutation, useDeleteTaskMutation, useAddTaskMutation } from 'projects/api/ProjectApi';
import { TeamOutlined, UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import type { MenuProps } from 'antd';

import UpdateTaskModal from './modals/UpdateTaskModal';
import TableComponent from './TableComponent';
import AddWorkersOnTaskModal from './modals/AddWorkersOnTaskModal';
import RemoveWorkersOnTaskModal from './modals/RemoveWorkersOnTaskModal';
import { useEffect, useRef } from 'react';
import GanttChartHeader from './GanttChartHeaderComponent';
import GanttChart from './GanttChartComponent';
import AddTaskModal from './modals/AddTaskModal';
import { useSelector } from 'react-redux';
import { getCurrentUserEmail } from 'store/store';
import { findTaskById, findWorkPackageById } from 'shared/functions/helper.function';

interface TasksComponentProps {
    project: ProjectDto | undefined;
    projectId: string;
    tasks?: TaskDto[];
    workpackageId?: string;
    workpackageRef?: number;
    clockingData: {
        taskClockings: Record<string, number>;
        taskClockingsByMonth: Record<string, Record<string, number>>; 
    }
}

const TasksComponent: React.FC<TasksComponentProps> = ({ project, projectId, tasks, workpackageId, workpackageRef, clockingData }) => {

    const { showModal, hideModal } = useModal();
    const [addTask] = useAddTaskMutation();
    const [updateTask] = useUpdateTaskMutation();
    const [deleteTask] = useDeleteTaskMutation();
    const [addWorkers] = useAddWorkersToTaskMutation();
    const [removeWorkers] = useDeleteWorkersFromTaskMutation();

    const titleDivRef = useRef<HTMLDivElement | null>(null);
    const renderDivsRef = useRef<HTMLDivElement[]>([]);
    const userEmail = useSelector(getCurrentUserEmail);
    useEffect(() => {
        const titleDiv = titleDivRef.current;
        const renderDiv = renderDivsRef.current;

        if (!titleDiv || !renderDiv) return;
        const syncScroll = (source: HTMLDivElement, target: HTMLDivElement[]) => {
            target.forEach((div: HTMLDivElement) => div.scrollLeft = source.scrollLeft);
        };
        const handleScroll = () => {
            syncScroll(titleDiv, renderDiv);

        };
        titleDiv.addEventListener('scroll', handleScroll);

        return () => {
            titleDiv.removeEventListener('scroll', handleScroll);
        };

    }, [clockingData]);

    const TASKS_COLUMNS = [
        {
            title: "Ref",
            dataIndex: 'reference',
            key: 'reference',
            render: (reference: string) => `T${workpackageRef}.${reference}`,
        },
        {
            title: 'Workers',
            key: 'workers',
            render: (_: any, record: TaskDto) => {
                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        type: 'group',
                        label: 'Workers',
                        children: [
                            ...(record.workers || []).map((worker: UserDto) => ({
                                label: `${worker.firstName} ${worker.lastName}`,
                                key: worker.id,
                                className: 'nonHoverable',
                            }))
                        ]
                    },
                ];

                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: '1',
                                    type: 'group',
                                    label: 'Workers',
                                    children: [
                                        ...(record.workers || []).map((worker: UserDto) => ({
                                            label: `${worker.firstName} ${worker.lastName}`,
                                            key: worker.id,
                                            className: 'nonHoverable',
                                        }))
                                    ]
                                },
                            ]
                        }}
                        trigger={['hover', 'click']}
                    >
                        <Button type="text">
                            <TeamOutlined style={{ fontSize: '150%' }} />
                            {record.workers.length}
                        </Button>
                    </Dropdown>
                );
            },
        },
        {
            title: 'Clocked Hours',
            dataIndex: 'clockedHours',
            key: 'clockedHours',
            onCell: () => ({
                style: { width: '8vw' },
            }),
            render: (dataIndex: number, record: TaskDto) => `${clockingData.taskClockings[record.id] ? clockingData.taskClockings[record.id] : 0}`,
        },
        {
            title: "Tasks",
            dataIndex: 'title',
            key: 'title',
            onCell: () => ({
                style: {
                    maxWidth: '32vw',
                }
            }),
            render: (dataIndex: string, record: TaskDto) => (
                <>
                    <strong>{record.title}</strong> : {record.description}
                </>
            ),
        },
        {
            title: () => (
                <div
                    ref={titleDivRef}
                    style={{
                        width: "32vw",
                        overflowX: 'scroll',
                        whiteSpace: 'nowrap',
                    }}
                >
                    <GanttChartHeader
                        startDate={project ? project.startDate : ""}
                        endDate={project ? project.endDate : ""}
                    />
                </div>
            ),
            key: 'timeline',
            render: (record: TaskDto) => {
                return (
                    <div
                        ref={el => el && renderDivsRef.current.push(el)}
                        style={{
                            width: "32vw",
                            overflowX: 'hidden',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <GanttChart
                            startDate={project ? project.startDate : ""}
                            endDate={project ? project.endDate : ""}
                            currentTaskId={record!.id}
                            projectId={projectId}
                            workPackageId={record!.workPackageId}
                            clockingData={clockingData.taskClockings[record.id] || 0}
                            clockingByMonth={clockingData.taskClockingsByMonth[record.id] || {}}
                        />

                    </div>
                )
            },
        },
    ]




    const handleAddTask = async (workPackageId: string, task: CreateTaskDto) => {

        const project = await addTask({ projectId: projectId, workPackageId, task });
    }

    const handleUpdateTask = async (workPackageId: string, taskId: string, task: UpdateTaskDto) => {
        const project = await updateTask({ projectId: projectId, workPackageId, taskId, task });
    }

    const handleDeleteTask = async (record: { id: string, workPackageId: string }) => {
        const project = await deleteTask({ projectId: projectId, workPackageId: record.workPackageId, taskId: record.id });
    }

    const handleAddWorkerToTask = async (record: { workPackageId: string, taskId: string, usersIds: UpdateTaskWorkersDto }) => {
        const project = await addWorkers({ projectId: projectId, workPackageId: record.workPackageId, taskId: record.taskId, usersIds: record.usersIds })
    }
    const handleRemoveWorkerToTask = async (record: { workPackageId: string, taskId: string, usersIds: UpdateTaskWorkersDto }) => {
        const project = await removeWorkers({ projectId: projectId, workPackageId: record.workPackageId, taskId: record.taskId, usersIds: record.usersIds })
    }

    const openAddTaskModal = (workpackageId: string) => {
        showModal(
            "addTaskModal",
            <AddTaskModal
                onClose={() => hideModal("addTaskModal")}
                onSubmit={(values) => {
                    handleAddTask(workpackageId, values).then(data => {
                        hideModal("addTaskModal")
                    })
                }}
                projectStartDate={project!?.startDate}
                projectEndDate={project!?.endDate}
            />
        );
    };

    const openUpdateTaskModal = (record: { id: string }) => {

        const editTask = findTaskById(project!, record.id, workpackageId!)

        showModal(
            "updateTaskModal",
            <UpdateTaskModal
                onClose={() => hideModal("updateTaskModal")}
                onSubmit={(values) => {
                    handleUpdateTask(workpackageId!, record.id, values).then(data => {
                        hideModal("updateTaskModal")
                    })
                }}
                projectStartDate={project!?.startDate}
                projectEndDate={project!?.endDate}
                initialValues={{
                    title: editTask ? editTask.title : '',
                    reference: editTask ? editTask.reference : 0,
                    description: editTask ? editTask.description : '',
                    startDate: editTask ? editTask.startDate : new Date().toDateString(),
                    endDate: editTask ? editTask.endDate : new Date().toDateString(),
                    status: editTask ? editTask.status : TaskDto.status.INACTIVE
                }}
            />
        );
    };
    
    const openAssignWorkerToTaskModal = (record: { id: string, workers: UserDto[] }) => {
        const workersIds = record.workers.map(worker => worker.id)
        showModal(
            "addWorkersToTaskModal",
            <AddWorkersOnTaskModal
                onClose={() => hideModal("addWorkersToTaskModal")}
                onSubmit={(values) => {
                    handleAddWorkerToTask({
                        workPackageId: workpackageId!,
                        taskId: record.id,
                        usersIds: values
                    });
                    hideModal("addWorkersToTaskModal");
                }}
                users={project!.workers.filter(worker => !workersIds.includes(worker.id))}
            />
        );
    };

    const openDeleteWorkerFromTaskModal = (record: { id: string, workers: UserDto[] }) => {
        const workersIds = record.workers.map(worker => worker.id)
        showModal(
            "removeWorkersFromTaskModal",
            <RemoveWorkersOnTaskModal
                onClose={() => hideModal("removeWorkersFromTaskModal")}
                onSubmit={(values) => {
                    handleRemoveWorkerToTask({
                        workPackageId: workpackageId!,
                        usersIds: values,
                        taskId: record.id,
                    });
                    hideModal("removeWorkersFromTaskModal");
                }}
                users={project!.workers.filter(worker => workersIds.includes(worker.id))}
            />
        );
    };

    return (

        <TableComponent
            data={project ? tasks || findWorkPackageById(project, workpackageId!)?.tasks || [] : []}
            columns={TASKS_COLUMNS}
            onAdd={() => ( userEmail === project?.director.email) ? openAddTaskModal(workpackageId!) : undefined}
            tooltip="task"
            onEdit={(userEmail === project?.director.email) ? openUpdateTaskModal : undefined}
            onDelete={(userEmail === project?.director.email) ? handleDeleteTask : undefined}
            bonusActionButtons={(record) => (userEmail === project?.director.email) ? [
                {
                    text: "+",
                    tooltip: "Add workers to task",
                    icon: <UserAddOutlined style={{ fontSize: '150%' }} />,
                    onBonusAction: () => openAssignWorkerToTaskModal(record),
                },
                {
                    text: "-",
                    tooltip: "Remove workers from task",
                    icon: <UserDeleteOutlined style={{ fontSize: '150%' }} />,
                    onBonusAction: () => openDeleteWorkerFromTaskModal(record)
                },
            ] : []}
        />
    )
}

export default TasksComponent
