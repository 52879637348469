import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Card, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useAddProjectMutation, useDeleteProjectMutation, useGetProjectsQuery, useLazyGetClockingQuery, useUpdateProjectMutation } from "projects/api/ProjectApi";
import { UpdateProjectDto, CreateProjectDto, PageRequestDto, ProjectDto, ProjectFilterParams, ClockingDto } from "shared/generated-sources";
import { RoutePathKeys } from "shared/constants/RoutePathKeys.enum";
import { useModal } from "shared/contexts/ModalContext";

import AddProjectModal from "components/modals/AddProjectModal";
import UpdateProjectModal from "components/modals/UpdateProjectModal";
import TableComponent from "components/TableComponent";

import { PROJECT_LIST_COLUMN_LAYOUT } from "shared/constants/tableColumnProps";

const ProjectListPage = () => {

    const [projects, setProjects] = useState<ProjectDto[]>([]);
    const [clockingPerProject, setClockingPerProject] = useState<Record<string, number>>({});
    const { showModal, hideModal } = useModal();

    const [addProject] = useAddProjectMutation();
    const [deleteProject] = useDeleteProjectMutation();
    const [updateProject] = useUpdateProjectMutation();

    const navigate = useNavigate();
    const location = useLocation();

    const pageRequest: PageRequestDto = {
        pageNumber: 0,
        pageSize: 15,
        sortBy: 'startDate',
        sortDirection: PageRequestDto.sortDirection.ASC
    }

    const filterParams: ProjectFilterParams = {
        director: location.pathname.includes('/projects/director'),
        worker: location.pathname.includes('/projects/worker'),
    };

    const { data, isLoading, isSuccess } = useGetProjectsQuery({
        pageRequest,
        filterParams
    },
        { refetchOnMountOrArgChange: true });

    const [triggerGetClocking, { data: clockingData, isLoading: isLoadingClocking, isSuccess: isSuccessClocking }] = useLazyGetClockingQuery();

    useEffect(() => {
        if (isSuccess) {
            setProjects(data.data || []);
            projectClocking();
        }
    }, [isSuccess, data, clockingData]);


    const findProjectById = (projectId: string): UpdateProjectDto => {
        return projects.filter(project => project.id === projectId)[0];
    }

    const handleRowClick = (record: { id: string }) => {
        navigate(`${RoutePathKeys.projects}/${record.id}`);
    };

    const handleAddProject = async (values: CreateProjectDto) => {
        const project = await addProject(values)
        setProjects([project.data as ProjectDto, ...projects])
    }

    const handleProjectEdit = async (projectId: string, updatedProjectDto: UpdateProjectDto) => {
        const response = await updateProject({ projectId, updatedProjectDto })
        let currentProjects = [response.data as ProjectDto, ...projects.filter(project => project.id !== projectId)]
        setProjects(currentProjects)
    }

    const handleProjectDelete = async (record: { id: string }) => {

        const response = await deleteProject({ projectId: record.id })
        if (response.data?.success) {
            setProjects(projects.filter(p => p.id !== record.id))
        }
    }

    const openAddProjectModal = () => {
        showModal(
            'addProjectModal',
            <AddProjectModal
                onClose={() => hideModal("addProjectModal")}
                onSubmit={(values) => {
                    handleAddProject(values)
                    hideModal("addProjectModal");
                }}
            />
        );
    };

    const projectClocking = async () => {
        try {
            const response = await triggerGetClocking({
                clockingParams: {
                    projectIds: projects.map((project: ProjectDto) => project.id),
                },
            });

            if (response.data) {
                const newClockingPerProject: Record<string, number> = {};

                response.data.forEach((clocking: ClockingDto) => {
                    if (clocking.projectId)
                        newClockingPerProject[clocking.projectId] = (newClockingPerProject[clocking.projectId] || 0) + clocking.hoursWorked;

                });

                setClockingPerProject(newClockingPerProject);
            }
        } catch (error) {
            console.error("Error fetching clocking data:", error);
        }
        return 0;
    }

    const openUpdateProjectModal = (record: { id: string }) => {

        let currentProject: UpdateProjectDto = findProjectById(record.id);
        showModal(
            'updateProjectModal',
            <UpdateProjectModal
                onClose={() => hideModal("updateProjectModal")}
                onSubmit={(values) => {
                    handleProjectEdit(record.id, values);
                    hideModal("updateProjectModal");
                }}
                initialValues=
                {{
                    name: currentProject ? currentProject.name : '',
                    startDate: currentProject ? currentProject.startDate : '',
                    endDate: currentProject ? currentProject.endDate : '',
                    status: currentProject ? currentProject.status : ProjectDto.status.INACTIVE,
                    allocatedPersonMonths: currentProject ? currentProject.allocatedPersonMonths : 0
                }}
            />
        );
    };
    const PROJECT_LIST_COLUMN_LAYOUT_UPDATED = PROJECT_LIST_COLUMN_LAYOUT.map((column) => {
        if (column.dataIndex === 'allocatedPersonMonths') {
            return {
                ...column,
                onCell: () => ({
                    style: { width: '10vw', },
                }),
                render: (_: number, record: ProjectDto) =>
                    `${record.consumedPersonMonth?.toFixed(3) || 0}/${record.allocatedPersonMonths || 0}`,
            };
        }
        if (column.dataIndex === 'clockedHours') {
            return {
                ...column,
                onCell: () => ({
                    style: { width: '8vw' },
                }),
                render: (_: number, record: ProjectDto) =>
                    `${clockingPerProject[record.id] || 0}`,
            };
        }
        return column;
    });


    if (isLoading) {
        return <Spin indicator={<LoadingOutlined spin />} size="large" />
    }

    return (
        <Card bordered={false} >
            <TableComponent
                data={data ? projects : []}
                columns={PROJECT_LIST_COLUMN_LAYOUT_UPDATED}
                onRowClick={handleRowClick}
                onAdd={location.pathname.includes('/projects/director')?openAddProjectModal:undefined}
                tooltip="project"
                onEdit={location.pathname.includes('/projects/director')?openUpdateProjectModal:undefined}
                onDelete={location.pathname.includes('/projects/director')?handleProjectDelete:undefined}
            />
        </Card>
    )
}

export default ProjectListPage;