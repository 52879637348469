import { Dispatch } from '@reduxjs/toolkit';

interface IApiMessagesProps {
    errorMessage?: string;
    successMessage?: string;
}

interface IActionProps<ResponseDto> {
    errorAction?: (error: any) => any;
    callbackAction?: (data: ResponseDto) => void;
    successActions: Array<(data: ResponseDto) => any> | Array<() => any>;
}

interface QueryHandlerProps<ResponseDto> extends IApiMessagesProps, IActionProps<ResponseDto> {
    dispatch: Dispatch;
    queryFulfilled: Promise<{ data: ResponseDto }>;
}

export const handleQueryStartedFunction = async <ResponseDto>(queryHandlerProps: QueryHandlerProps<ResponseDto>) => {

    const {
              dispatch,
              queryFulfilled,
              successMessage,
              errorMessage,
              successActions = [],
              errorAction,
              callbackAction
          } = queryHandlerProps;

    try {
        const { data } = await queryFulfilled;

        successActions.forEach( action => {
            if( action ) {
                dispatch( action( data ) );
            }
        } );

        if( callbackAction ) {
            callbackAction( data );
        }

        if( successMessage ) {
            console.log( successMessage );
        }

    } catch( error ) {

        if( errorMessage ) {
            console.error( errorMessage );
        }

        if( errorAction ) {
            dispatch( errorAction( error ) );
        }
    } finally {
    }
};
