import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { Button, Form, FormProps, Input, Typography, message     } from "antd";

import { LoginUserDto } from "shared/generated-sources";
import { useLoginUserMutation } from "auth/api/AuthApi";
import { ErrorMessage } from "shared/components/ErrorMessage";

import { RootState } from "store/store";
import { isAuthenticationValid } from "../auth/api/AuthSlice";
import { RoutePathKeys } from "shared/constants/RoutePathKeys.enum";


const onLoginFailed: FormProps<LoginUserDto>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

const LoginPage = () => {
    const [loginUser] = useLoginUserMutation();
    const navigate = useNavigate();
    const token = useSelector<RootState>((state) => state.authentication.token);
    const tokenExpirationDate = useSelector<RootState>((state) => state.authentication.expirationDate) as string;

    const handleLogin: FormProps<LoginUserDto>['onFinish'] = async (values) => {
        try {
            const response = await loginUser(values);
            if ('error' in response && response.error) {
                if ('status' in response.error && response.error.status === 'FETCH_ERROR') {
                    ErrorMessage({
                        title: 'Server Connection Error',
                        errorMessage: 'Unable to connect to the server. Please check if the server is running and try again.'
                    });
                    return;
                }

                const errorMessage = 'data' in response.error 
                    ? (response.error.data as any)?.message || 'Failed to login. Wrong password or email. Please try again.'
                    : 'Failed to login. Wrong password or email. Please try again.';
                
                ErrorMessage({
                    title: 'Login Error',
                    errorMessage: errorMessage
                });
            }
        } catch (error) {
            ErrorMessage({
                title: 'Login Error',
                errorMessage: 'An unexpected error occurred. Please try again.'
            });
        }
    };

    useEffect(() => {
        if (token && isAuthenticationValid(tokenExpirationDate)) {
            navigate("/dashboard")
        }
    }, [token]);


    return (
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                onFinish={handleLogin}
                onFinishFailed={onLoginFailed}
                autoComplete="off"
            >
                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Typography.Title level={3} >
                        Login
                    </Typography.Title>
                </Form.Item>
                <Form.Item<LoginUserDto>
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<LoginUserDto>
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 8, span: 16 }} style={{ marginTop: -16 }}>
                    <Typography.Link href={RoutePathKeys.passwordResetRequest} style={{ float: 'right' }}>
                        Forgot Password?
                    </Typography.Link>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
    )
}
export default LoginPage;
