import { RoutePathKeys } from './RoutePathKeys.enum';

export const navigation = {
    public: [
        {
            key: RoutePathKeys.homepage,
            label: 'Homepage',
            target: RoutePathKeys.homepage
        },
        {
            key: RoutePathKeys.login,
            label: 'Login',
            target: RoutePathKeys.login
        },

    ],
    private: [
        {
            key: RoutePathKeys.passwordResetRequest,
            label: 'Password Reset Request',
            target: RoutePathKeys.passwordResetRequest
        },
        {
            key: RoutePathKeys.passwordReset,
            label: 'Password Reset',
            target: RoutePathKeys.passwordReset
        }],
    authorized: [
        {
            key: RoutePathKeys.dashboard,
            label: 'Dashboard',
            target: RoutePathKeys.dashboard
        },
        {
            key: RoutePathKeys.accountant,
            label: 'Accountant',
            target: RoutePathKeys.accountant
        },
        {
            key: RoutePathKeys.projects,
            label: 'Projects',
            target: RoutePathKeys.projects
        },
        {
            key: RoutePathKeys.profile,
            label: 'Profile',
            target: RoutePathKeys.profile
        },
        {
            key: RoutePathKeys.users,
            label: 'Users',
            target: RoutePathKeys.users
        },
        {
            key: RoutePathKeys.directorProjects,
            label: 'DirectorProjects',
            target: RoutePathKeys.directorProjects
        },
        {
            key: RoutePathKeys.workerProjects,
            label: 'WorkerProjects',
            target: RoutePathKeys.workerProjects
        },
        {
            key: RoutePathKeys.projects,
            label: 'Projects',
            target: RoutePathKeys.projects
        },
        {
            key: RoutePathKeys.monthClosing,
            label: 'MonthClosing',
            target: RoutePathKeys.monthClosing
        }
    ]
};
