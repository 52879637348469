import { useState } from "react";
import { Button, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { TableProps } from "shared/constants/interfaces/TableInterfaces";

const TableComponent = <T extends Object>({ data, columns, pagination, onRowClick, key = "id", onEdit, onDelete, onAdd, tooltip, expandComponent: ExpandableComponent, bonusActionButtons }: TableProps<T>) => {

    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

    const handleRowClick = (record: any) => {

        const id = record.id;
        if (expandedRowKeys.includes(id)) {
            setExpandedRowKeys(expandedRowKeys.filter((k) => k !== id));
        } else {
            setExpandedRowKeys([...expandedRowKeys, id]);
        }
    };

    const expandableProps = ExpandableComponent
        ? {
            expandedRowRender: (record: T, index: number) => ExpandableComponent(record, index),
            rowExpandable: () => true,
            expandedRowKeys: expandedRowKeys,
            showExpandColumn: false,
        }
        : undefined;

    const actionsColumn: ColumnsType = [{
        title: (
            onAdd ?
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Tooltip placement="bottom" title={`Add ${tooltip}`}>
                        <Button type="primary" onClick={(e) => {
                            e.stopPropagation();
                            if (onAdd) {
                                onAdd();
                            }
                        }}>
                            <PlusSquareOutlined style={{ fontSize: '150%' }} />
                        </Button>
                    </Tooltip>
                </div > : null
        ),
        key: "action",
        render: (_, record) => (
            <div style={buttonsContainer}>
                {bonusActionButtons && (
                    bonusActionButtons(record).map((button, index) =>
                        <Tooltip key={`Actions-${index}`} placement="bottom" title={`${button.tooltip}`}>
                            <Button
                                style={{ marginRight: "10px" }}
                                key={`Actions-${index}`}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    button.onBonusAction(record)
                                }}
                            >
                                {button.icon ? button.icon : button.text}
                            </Button>
                        </Tooltip>
                    )

                )}
                {onEdit && <Tooltip placement="bottom" title={`Edit ${tooltip}`}>
                    <Button style={{ marginRight: "10px" }} key="Edit" onClick={(e) => {
                        e.stopPropagation();
                        return onEdit!(record);
                    }}>
                        <EditOutlined style={{ fontSize: '150%' }} />
                    </Button>
                </Tooltip>}

                {onDelete && <Tooltip placement="bottom" title={`Delete ${tooltip}`}>
                    < Button key="Delete" onClick={(e) => {
                        e.stopPropagation();
                        return onDelete!(record);
                    }}>
                        <DeleteOutlined style={{ fontSize: '150%' }} />
                    </Button>
                </Tooltip>}
            </div >
        ),
    }]

    return (
        <div>
            <Table
                rowKey={key}
                columns={[...columns, ...actionsColumn]}
                expandable={expandableProps}
                dataSource={data}
                pagination={pagination ? pagination : false}
                onRow={(record, rowIndex) => ({
                    onClick: () => {
                        if (onRowClick) {
                            onRowClick(record);
                        }
                        else {
                            handleRowClick(record)
                        }

                    },
                })}
            />
        </div>
    )
}

export default TableComponent;

const buttonsContainer = {
    display: 'flex',
    justifyContent: "flex-end",
};