/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateTaskDto = {
    title: string;
    reference: number;
    description: string;
    startDate: string;
    status: UpdateTaskDto.status;
    endDate: string;
};
export namespace UpdateTaskDto {
    export enum status {
        ACTIVE = 'ACTIVE',
        DELETED = 'DELETED',
        INACTIVE = 'INACTIVE',
    }
}

