const DashboardPage = () => {
    return (
            <nav>
                dashboard
            </nav>
    )
}

export default DashboardPage;

