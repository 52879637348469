import { UserDto, ProjectDto, TaskDto } from 'shared/generated-sources';
import TableComponent from './TableComponent';
import { useEffect, useRef, useState } from 'react';
import { findWorkPackageById } from 'shared/functions/helper.function';
import { InputNumber, Form } from 'antd';

interface TasksComponentProps {
  project: ProjectDto | undefined;
  tasks?: TaskDto[];
  maxHoursForUser: number;
  currentMonthClockings: Record<string, number>;
  onTaskHoursChange?: (taskId: string, hours: number) => void;
  shouldResetInputs?: boolean;
  onInputsReset?: () => void;
}

const MonthClosingTasksComponent: React.FC<TasksComponentProps> = ({ 
  project, 
  tasks, 
  maxHoursForUser,
  currentMonthClockings,
  onTaskHoursChange,
  shouldResetInputs,
  onInputsReset 
}) => {
  const [taskHours, setTaskHours] = useState<Record<string, number>>({});
  const [committedHours, setCommittedHours] = useState<Record<string, number>>({});
  const [maxHoursRemainingForUser, setMaxHoursRemainingForUser] = useState<number>(maxHoursForUser);
  const [form] = Form.useForm();
  const inputRef = useRef<HTMLInputElement>(null);

  console.log(maxHoursRemainingForUser)

  useEffect(() => {
    if (shouldResetInputs) {
      setTaskHours({});
      setCommittedHours({});
      setMaxHoursRemainingForUser(maxHoursForUser);
      form.resetFields();
      if (onInputsReset) {
        onInputsReset();
      }
    }
  }, [shouldResetInputs, onInputsReset, form, maxHoursForUser]);

  const handleHoursChange = (taskId: string, value: number | null) => {
    const newValue = value || 0;
    setTaskHours(prev => {
      const updated = { ...prev, [taskId]: newValue };
      if (onTaskHoursChange) {
        onTaskHoursChange(taskId, newValue);
      }
      return updated;
    });
  };

  const commitHours = (taskId: string, value: number) => {
    const previousCommittedValue = committedHours[taskId] || 0;
    const hoursToReturn = previousCommittedValue;
    const hoursToCommit = value;

    setCommittedHours(prev => ({
      ...prev,
      [taskId]: hoursToCommit
    }));

    setMaxHoursRemainingForUser(prev => 
      prev + hoursToReturn - hoursToCommit
    );
  };

  const TASKS_COLUMNS = [
    {
      title: 'Ref',
      dataIndex: 'reference',
      key: 'reference',
      render: (reference: string, record: TaskDto) => {
        const wp = project ? findWorkPackageById(project, record.workPackageId) : null;
        const wpRef = wp ? wp.reference : 'N/A';
        return `T${wpRef}.${reference}`;
      },
    },
    {
      title: 'Current Month Hours',
      dataIndex: 'currentMonthHours',
      key: 'currentMonthHours',
      onCell: () => ({ style: { width: '300px' } }),
      render: (_: number, record: TaskDto) => `${currentMonthClockings[record.id] || 0}`,
    },
    {
      title: 'Tasks',
      dataIndex: 'title',
      key: 'title',
      onCell: () => ({ style: { maxWidth: 'auto' } }),
      render: (_: string, record: TaskDto) => (
        <>
          <strong>{record.title}</strong> : {record.description}
        </>
      ),
    },
    {
      title: 'Add Hours',
      key: 'addHours',
      render: (_: any, record: TaskDto) => (
        <Form.Item
          name={`hoursWorked_${record.id}`}
          style={{ 
            margin: 0,
            position: 'relative'
          }}
          rules={[
            {
              validator: (_, value) => {
                if (!value) return Promise.resolve();
                
                const numberValue = Number(value);
                const taskId = record.id;
                const otherCommittedHours = Object.entries(committedHours)
                  .filter(([id]) => id !== taskId)
                  .reduce((sum, [_, hours]) => sum + hours, 0);
                
                const availableHours = maxHoursForUser - otherCommittedHours;

                if (numberValue >= 0 && numberValue <= availableHours) {
                  return Promise.resolve();
                }
                
                handleHoursChange(record.id, 0);
                return Promise.reject(`Maximum ${availableHours} hours allowed`);
              },
            },
          ]}
        >
          <div style={{ position: 'relative' }}>
            <InputNumber
              ref={inputRef}
              min={0}
              type='number'
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const value = inputRef.current?.value ? Number(inputRef.current.value) : 0;
                  commitHours(record.id, value);
                  (e.target as HTMLElement).blur();
                }
              }}
              onChange={(val) => handleHoursChange(record.id, val)}
              value={taskHours[record.id]}
              style={{ width: '100px' }}
            />
            <div 
              className="ant-form-item-explain-error"
              style={{
                position: 'absolute',
                top: '100%',
                left: '50%',
                transform: 'translateX(-50%)',
                whiteSpace: 'nowrap',
                fontSize: '12px',
                zIndex: 1
              }} 
            />
          </div>
        </Form.Item>
      ),
    },
  ];

  return (
    <Form form={form}>
      <TableComponent
        data={tasks!}
        columns={TASKS_COLUMNS}
        tooltip="task"
      />
    </Form>
  );
};

export default MonthClosingTasksComponent;
