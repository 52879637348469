import React, { useState } from 'react';
import { InputNumber } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Wage_Roles } from 'shared/constants/enum';

import TableComponent from './TableComponent';


export interface WageRoleData {
  id: string;
  role: string;
  wage: number;
}

const defaultData: WageRoleData[] = [
  { id: 'Assistent', role: 'Assistent', wage: Wage_Roles.Assistent },
  { id: 'PhD_Student', role: 'PhD Student', wage: Wage_Roles.PhD_Student },
  { id: 'Engineer', role: 'Engineer', wage: Wage_Roles.Engineer },
  { id: 'PhD', role: 'PhD', wage: Wage_Roles.PhD },
  { id: 'Project_Director', role: 'Project Director', wage: Wage_Roles.Project_Director },
];

const MaximalWageTable: React.FC = () => {

  const [data, setData] = useState<WageRoleData[]>(defaultData);

  const handleWageChange = (id: string, newWage: number | null) => {
    if (newWage === null) return;
    setData(prev =>
      prev.map(item => (item.id === id ? { ...item, wage: newWage } : item))
    );
  };

  const columns: ColumnsType<WageRoleData> = [
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Wage',
      dataIndex: 'wage',
      key: 'wage',
      render: (_: any, record: WageRoleData) => (
        <InputNumber
          value={record.wage}
          onChange={value => handleWageChange(record.id, value)}
        />
      ),
    },
  ];

  return (
    <TableComponent
      data={data}
      columns={columns}
      tooltip="wage role"
      onAdd={()=>console.log("Add Maximal Wage")}
    />
  );
};

export default MaximalWageTable;
