import React, { useState, useEffect } from "react";
import { Card } from "antd";
import type { TablePaginationConfig } from "antd";
import { useSelector } from "react-redux";
import { getCurrentUserRole } from "store/store";
import { CreateUserDto, PageRequestDto, UserDto } from "shared/generated-sources";
import { useAddUserMutation, useGetUsersQuery } from "projects/api/ProjectApi";
import { useModal } from "shared/contexts/ModalContext";
import AddUserToPlatformModal from "components/modals/AddUserToPlatformModal";
import TableComponent from "components/TableComponent";
import { USER_LIST_COLUMN_LAYOUT } from "shared/constants/tableColumnProps";

const UsersPage = () => {
    const { showModal, hideModal } = useModal();
    const [addUser] = useAddUserMutation();
    const [users, setUsers] = useState<UserDto[]>([]);
    const userRole: string = useSelector(getCurrentUserRole)[0];
    const [pageRequest, setPageRequest] = useState<PageRequestDto>({
        pageNumber: 0,
        pageSize: 10,
        sortBy: "firstName",
        sortDirection: PageRequestDto.sortDirection.ASC,
    });

    const { data, isSuccess } = useGetUsersQuery(
        { pageRequest },
        { refetchOnMountOrArgChange: true }
    );

    useEffect(() => {
        if (isSuccess && data) {
            setUsers(data.data || []);
        }
    }, [isSuccess, data]);

    const handleAddUserToPlatform = async (values: CreateUserDto) => {
        const user = await addUser(values);
        if (user.data) setUsers([user.data as UserDto, ...users]);
    };

    const openAddUserToPlatformModal = () => {
        showModal(
            "addUserToPlatformModal",
            <AddUserToPlatformModal
                userRole={userRole}
                onClose={() => hideModal("addUserToPlatformModal")}
                onSubmit={(values) => {
                    handleAddUserToPlatform(values);
                    hideModal("addUserToPlatformModal");
                }}
            />
        );
    };

    const pagination: TablePaginationConfig = {
        current: (pageRequest.pageNumber || 0) + 1,
        pageSize: pageRequest.pageSize,
        total: data?.totalItems! || 0,
        onChange: (page: number, pageSize?: number) =>
            setPageRequest((prev) => ({
                ...prev,
                pageNumber: page - 1,
                pageSize: pageSize || prev.pageSize,
            })),
    };

    return (
        <Card bordered={false}>
            <TableComponent
                pagination={pagination}
                data={data ? users : []}
                columns={USER_LIST_COLUMN_LAYOUT}
                onAdd={openAddUserToPlatformModal}
                tooltip="user"
            />
        </Card>
    );
};

export default UsersPage;
