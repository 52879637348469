import React, { useEffect, useMemo, useRef } from "react";
import * as d3 from "d3";
import AddClockingModal from "./modals/AddClockingModal";
import { CreateClockingDto, TaskDto } from "shared/generated-sources";
import { useModal } from "shared/contexts/ModalContext";
import dayjs from "dayjs";
import { projectsApi } from "projects/api/ProjectApi";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { findTaskById } from "shared/functions/helper.function";

interface GanttChartProps {
  startDate: string;
  endDate: string;
  currentTaskId: string | null;
  projectId: string;
  workPackageId: string;
  clockingData: number;
  clockingByMonth: Record<string, number>;
  onClockingUpdate?: (clockings: CreateClockingDto[]) => Promise<void>;
}

const GanttChart: React.FC<GanttChartProps> = ({ 
  startDate, 
  endDate, 
  currentTaskId, 
  projectId, 
  workPackageId,
  clockingByMonth,
  onClockingUpdate 
}) => {
  const project = useSelector((state: RootState) =>
    projectsApi.endpoints.getProject.select({ id: projectId })(state)?.data
  );
  const currentTask = useMemo(() => {
    if (!project || !currentTaskId) return undefined;
    return findTaskById(project, currentTaskId, workPackageId);
  }, [project, workPackageId, currentTaskId]);
  const { showModal, hideModal } = useModal();
  const svgRef = useRef<SVGSVGElement | null>(null);

  const getMonthsBetween = (start: Date, end: Date) => {
    const months: Date[] = [];
    let date = new Date(start.getFullYear(), start.getMonth(), 1);

    while (date <= end) {
      months.push(new Date(date));
      date.setMonth(date.getMonth() + 1);
    }

    return months;
  };

  const getMonthDateRange = (month: Date, highlightStart: Date | null, highlightEnd: Date | null) => {
    const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
    const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const startDate = highlightStart && highlightStart.getMonth() === month.getMonth() && highlightStart.getFullYear() === month.getFullYear() ? highlightStart : monthStart;
    const endDate = highlightEnd && highlightEnd.getMonth() === month.getMonth() && highlightEnd.getFullYear() === month.getFullYear() ? highlightEnd : monthEnd;
    return { startDate, endDate };
  };

  const isHighlightedMonth = (month: Date) => {
    if (!currentTask) return false;
    const highlightStart = currentTask.startDate ? new Date(currentTask.startDate) : null;
    const highlightEnd = currentTask.endDate ? new Date(currentTask.endDate) : null;
    if (!highlightStart || !highlightEnd) return false;

    highlightStart.setHours(0, 0, 0, 0);
    highlightEnd.setHours(0, 0, 0, 0);

    const monthStart = new Date(month.getFullYear(), month.getMonth(), 1);
    const monthEnd = new Date(month.getFullYear(), month.getMonth() + 1, 0);

    return highlightStart <= monthEnd && highlightEnd >= monthStart;
  };
  const openAddClockingModal = (task: TaskDto, start: Date, end: Date) => {
    showModal(
      "addClockingModal",
      <AddClockingModal
        startDate={dayjs(start).startOf("day")}
        endDate={dayjs(end).endOf("day")}
        users={task.workers || []}
        task={task}
        projectId={projectId}
        workPackageId={workPackageId}
        onClose={() => hideModal("addClockingModal")}
        onSubmit={(values, date) => {
          handleAddClockings(task.id, date, values);
        }}
      />
    );
  };

  const handleAddClockings = async (taskId: string, date: string, clockings: CreateClockingDto[]) => {
    if (onClockingUpdate) {
      await onClockingUpdate(clockings);
      hideModal("addClockingModal");
    }
  };

  useEffect(() => {
    if (!currentTask || !svgRef.current) return;
    const months = getMonthsBetween(new Date(startDate), new Date(endDate));
    const monthWidth = 50;
    const totalWidth = months.length * monthWidth;
    const height = 50;
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();
    svg.attr("width", totalWidth);
    
    svg.selectAll(".month")
      .data(months)
      .enter()
      .append("rect")
      .attr("class", "month")
      .attr("x", (d, i) => i * monthWidth)
      .attr("y", 0)
      .attr("width", monthWidth)
      .attr("height", height)
      .attr("fill", d => (isHighlightedMonth(d) ? "#baece1" : "white"))
      .attr("stroke", "black")
      .on("mouseover", function (event, d) {
        d3.select(this).attr("fill", () => (isHighlightedMonth(d) ? "#00b197" : "white"));
      })
      .on("mouseout", function (event, d) {
        d3.select(this).attr("fill", isHighlightedMonth(d) ? "#baece1" : "white");
      })
      .on("click", (event, d) => {
        if (isHighlightedMonth(d)) {
          const { startDate: sDate, endDate: eDate } = getMonthDateRange(d, new Date(currentTask.startDate), new Date(currentTask.endDate));
          openAddClockingModal(currentTask, sDate, eDate);
        }
      });

    svg.selectAll(".label")
      .data(months)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", (d, i) => (i * monthWidth) + (monthWidth / 2))
      .attr("y", height / 2)
      .attr("text-anchor", "middle") 
      .style("font-size", "1rem")
      .style("pointer-events", "none")
      .text((d) => {
        const monthKey = dayjs(d).format("YYYY-MM");
        return clockingByMonth[monthKey] || "";
      });
  }, [startDate, endDate, currentTask, clockingByMonth]);

  return <svg ref={svgRef} height={50}></svg>;
};

export default GanttChart;
