import { message } from 'antd';
import React from 'react';

interface ErrorMessageProps {
    title?: string;
    errorMessage: string;
}

export const ErrorMessage = ({ title = 'Error', errorMessage }: ErrorMessageProps) => {
    message.error({
        content: <div onClick={() => message.destroy()} style={{ cursor: 'pointer' }}>
            <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                {title}
            </div>
            {errorMessage}
            <div style={{ fontSize: '0.9em', marginTop: '8px', color: '#8c8c8c' }}>
                Click to dismiss
            </div>
        </div>,
        duration: 0,
        key: 'errorMessage',
        style: {
            whiteSpace: 'pre-wrap',
            padding: '12px',
            cursor: 'pointer'
        }
    });
}; 