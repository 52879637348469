import React, { useState } from 'react';
import { Button } from 'antd';
import AccountantCalendarComponent from 'components/AccountantCalendarComponent';
import MaximalWageTableComponent from 'components/MaximalWageTableComponent';
import UsersWageTableComponent from 'components/UsersWageTableComponent';

type MenuItem = 'calendar' | 'maximalWage' | 'usersWage';

const AccountPage: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<MenuItem>('calendar');

  const renderContent = () => {
    switch (activeMenu) {
      case 'calendar':
        return <AccountantCalendarComponent />;
      case 'maximalWage':
        return <MaximalWageTableComponent />;
      case 'usersWage':
        return <UsersWageTableComponent/>;
      default:
        return null;
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Button
          type={activeMenu === 'calendar' ? 'primary' : 'default'}
          onClick={() => setActiveMenu('calendar')}
        >
          Calendar
        </Button>
        <Button
          type={activeMenu === 'maximalWage' ? 'primary' : 'default'}
          onClick={() => setActiveMenu('maximalWage')}
        >
          Maximal Wage
        </Button>
        <Button
          type={activeMenu === 'usersWage' ? 'primary' : 'default'}
          onClick={() => setActiveMenu('usersWage')}
        >
          Users Wage
        </Button>
      </div>
      {renderContent()}
    </div>
  );
};

export default AccountPage;
