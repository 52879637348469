import { useParams } from "react-router";
import { Card, Button, message } from "antd";
import { useGetProjectQuery, useLazyGetClockingQuery, useAddMonthlyClockingsMutation, useGetFreeDaysQuery } from "projects/api/ProjectApi";
import { ProjectDto, WorkPackageDto, UserDto, TaskDto, FreeDayParamDto } from "shared/generated-sources";
import MonthClosingTasksComponent from "components/MonthClosingTasksComponent";
import TableComponent from "components/TableComponent";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { findTaskById } from "shared/functions/helper.function";
import { HOURLY_WAGE, MAX_HOURS_PER_DAY } from "shared/constants/constants";

const MonthClosingPage = () => {
    const { id } = useParams();
    const [project, setProject] = useState<ProjectDto | undefined>(undefined);
    const [currentMonthClockedHoursPerUser, setCurrentMonthClockedHoursPerUser] = useState<Record<string, number>>({});
    const [currentMonthClockingsPerTask, setCurrentMonthClockingsPerTask] = useState<Record<string, Record<string, number>>>({});
    const [taskHoursMap, setTaskHoursMap] = useState<Record<string, Record<string, number>>>({});
    const [shouldResetInputs, setShouldResetInputs] = useState(false);
    const [addMonthlyClockings] = useAddMonthlyClockingsMutation();
    

    const freeDayParams: FreeDayParamDto = {
        month: dayjs().month() + 1,
        year: dayjs().year(),
    };

    const { data: freeDaysData, isSuccess: isSuccessFreeDays } = useGetFreeDaysQuery(
        { freeDayParams },
        { refetchOnMountOrArgChange: true }
    );

    const [triggerGetClocking] = useLazyGetClockingQuery();
    const { data, isSuccess } = useGetProjectQuery({ id: id! }, { refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (isSuccess) {
            setProject(data);
        }

    }, [isSuccess, data, isSuccessFreeDays, freeDaysData]);

    
    useEffect(() => {
        if (project) {
            triggerGetClocking({
                clockingParams: {
                    workerIds: project.workers.map(worker => worker.id),
                    startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
                    endDate: dayjs().endOf('month').format('YYYY-MM-DD')
                }
            }).then((response) => {
                if (response.data) {
                    const userCurrentMonthClockedHours: Record<string, number> = {};
                    const taskCurrentMonthClockedHours: Record<string, Record<string, number>> = {};

                    response.data.forEach((clocking: any) => {
                        if (clocking.workerId) {
                            userCurrentMonthClockedHours[clocking.workerId] =
                                (userCurrentMonthClockedHours[clocking.workerId] || 0) + clocking.hoursWorked;

                            if (clocking.taskId) {
                                if (!taskCurrentMonthClockedHours[clocking.taskId]) {
                                    taskCurrentMonthClockedHours[clocking.taskId] = {};
                                }
                                taskCurrentMonthClockedHours[clocking.taskId][clocking.workerId] =
                                    (taskCurrentMonthClockedHours[clocking.taskId][clocking.workerId] || 0) + clocking.hoursWorked;
                            }
                        }
                    });

                    setCurrentMonthClockedHoursPerUser(userCurrentMonthClockedHours);
                    setCurrentMonthClockingsPerTask(taskCurrentMonthClockedHours);
                }
            });
        }
    }, [project, triggerGetClocking]);

    const handleTaskHoursChange = (userId: string, taskId: string, hours: number) => {
        setTaskHoursMap(prev => ({
            ...prev,
            [userId]: {
                ...(prev[userId] || {}),
                [taskId]: hours
            }
        }));
    };

    const handleMonthClosing = async () => {
        if (!project) return;

        try {
            const userMonthlyClockings = Object.entries(taskHoursMap)
                .map(([userId, taskHours]) => {
                    const populateClockingDataDto = Object.entries(taskHours)
                        .map(([taskId, hours]) => {
                            const task = project.workPackages
                                .flatMap(wp => wp.tasks)
                                .find(t => t.id === taskId);
                            if (!task?.workPackageId) return null;
                            return {
                                taskId,
                                workPackageId: task.workPackageId,
                                hours
                            };
                        })
                        .filter((dto): dto is { taskId: string; workPackageId: string; hours: number } =>
                            dto !== null && dto.hours > 0
                        );

                    if (populateClockingDataDto.length === 0) return null;

                    return {
                        userId,
                        populateClockingDataDto
                    };
                })
                .filter((userClocking): userClocking is { userId: string; populateClockingDataDto: { taskId: string; workPackageId: string; hours: number }[] } =>
                    userClocking !== null
                );

            if (userMonthlyClockings.length === 0) {
                message.warning('No hours to submit');
                return;
            }

            const payload = {
                projectId: project.id,
                userMonthlyClockings
            };

            await addMonthlyClockings(payload).unwrap();
            message.success('Successfully submitted all clockings');
            setTaskHoursMap({});
            setShouldResetInputs(true);
            if (project) {
                const taskIds = project.workPackages.flatMap((wp: WorkPackageDto) =>
                    wp.tasks.map((task: TaskDto) => task.id)
                );
                if (taskIds.length > 0) {
                    triggerGetClocking({ clockingParams: { workerIds: project.workers.map(worker => worker.id) } });
                }
            }
        } catch (error) {
            console.error("Error submitting clockings:", error);

            const key = `error-${Date.now()}`;
            const defaultMessage = 'An error occurred while submitting clockings';
            const errorMessage = ((error as any)?.data?.message as string) || defaultMessage;

            const processedErrorLines = errorMessage
                .split('\n')
                .map((line, index) => {
                    if (index === 0) return line;

                    const words = line.split(' ');
                    const taskId = words[words.length - 1];
                    const task = findTaskById(project, taskId);

                    if (task) {
                        words[words.length - 1] = task.title;
                        return words.join(' ');
                    }
                    return line;
                });

            const ErrorMessageContent = () => (
                <div onClick={() => message.destroy(key)} style={{ cursor: 'pointer' }}>
                    <div style={{ fontWeight: 'bold', marginBottom: '8px' }}>
                        Error Submitting Clockings
                    </div>
                    {processedErrorLines.map((line, i) => (
                        <div key={i} style={{ margin: '4px 0' }}>{line}</div>
                    ))}
                    <div style={{ fontSize: '0.9em', marginTop: '8px', color: '#8c8c8c' }}>
                        Click to dismiss
                    </div>
                </div>
            );

            message.error({
                content: <ErrorMessageContent />,
                duration: 0,
                key,
                style: {
                    whiteSpace: 'pre-wrap',
                    padding: '12px',
                    cursor: 'pointer'
                }
            });
        }
    };

    const handleExpandUsers = (user: UserDto) => {
        if (project) {
            const tasksWithUser = project.workPackages
                .flatMap((workpackage: WorkPackageDto) =>
                    workpackage.tasks.filter((task: TaskDto) => {
                        const isUserAssigned = task.workers.some((worker) => worker.id === user.id);
                        return isUserAssigned;
                    })
                );

            const userTaskHours: Record<string, number> = {};

            tasksWithUser.forEach(task => {
                userTaskHours[task.id] = (currentMonthClockingsPerTask[task.id]?.[user.id] || 0) + (taskHoursMap[user.id]?.[task.id] ?? 0);
            });

            return (
                <MonthClosingTasksComponent
                    project={project}
                    maxHoursForUser={(dayjs().daysInMonth() - freeDaysData?.length!) * (MAX_HOURS_PER_DAY - user.externalWorkHours) - (currentMonthClockedHoursPerUser[user.id] || 0)}
                    tasks={tasksWithUser}
                    currentMonthClockings={userTaskHours}
                    onTaskHoursChange={(taskId, hours) => handleTaskHoursChange(user.id, taskId, hours)}
                    shouldResetInputs={shouldResetInputs}
                    onInputsReset={() => setShouldResetInputs(false)}
                />
            );
        }
        return <></>;
    };

    const PROJECT_USER_LIST_COLUMN_LAYOUT = [
        {
            title: "Name",
            render: (text: string, record: { firstName: string; lastName: string }) =>
                `${record.firstName} ${record.lastName}`,
            key: "name",
        },
        {
            title: "Current Month Hours",
            render: (text: string, record: UserDto) =>
                (currentMonthClockedHoursPerUser[record.id] || 0) +
                (Object.values(taskHoursMap[record.id] || {}).reduce((acc: number, curr: number) => acc + curr, 0)),
            width: "auto",
            key: "currentMonthHours",
        },
        {
            title: "Hourly Wage",
            render: (text: string) => HOURLY_WAGE,
            width: "auto",
            key: "HourlyWage",
        },
        {
            title: "Total",
            render: (text: string, record: UserDto) => {
                const clocked = (currentMonthClockedHoursPerUser[record.id] || 0) +
                    (Object.values(taskHoursMap[record.id] || {}).reduce((acc: number, curr: number) => acc + curr, 0));
                return clocked * HOURLY_WAGE;
            },
            width: "auto",
        },
        {
            title: "Maximal wage",
            render: (text: string) => 100,
            width: "auto",
            key: "MaximalWage",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
    ];

    return (
        <>
            {project !== undefined ? (
                <div>
                    <Card
                        title={
                            <>
                                Project Name: {project.name} <br />
                                Month Closing for: {dayjs().format("MMMM YYYY")}
                            </>
                        }
                        bordered={false}
                        extra={
                            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
                                <Link to={`/projects/${project.id}`}>Back to Project View</Link>
                            </div>
                        }
                    >
                        <TableComponent
                            data={project.workers}
                            columns={PROJECT_USER_LIST_COLUMN_LAYOUT}
                            tooltip="user"
                            expandComponent={(record: UserDto) => handleExpandUsers(record)}
                        />

                        <Button
                            type="primary"
                            onClick={handleMonthClosing}
                            disabled={Object.keys(taskHoursMap).length === 0}
                            style={{ marginTop: '16px' }}
                        >
                            Submit All Clockings
                        </Button>
                    </Card>
                </div>
            ) : (
                <div>
                    <Card title="Project not found" bordered={false} />
                </div>
            )}
        </>
    );
};

export default MonthClosingPage;
