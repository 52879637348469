import { Outlet, useNavigate } from "react-router";
import { Avatar, Button, Flex, Tooltip } from "antd";
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

import { Header } from "antd/es/layout/layout";
import { logout } from "auth/api/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUserEmail, getCurrentUserRole } from "store/store";

import { RoutePathKeys } from "shared/constants/RoutePathKeys.enum";
import { ModalProvider } from "shared/contexts/ModalContext";


import SidenavComponent from "components/SidenavComponent";

import styles from './styles/basiclayout.module.css';


const AuthorizedLayout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const userEmail=useSelector(getCurrentUserEmail);
    const userRole=useSelector(getCurrentUserRole);
    

    const handleLogout = () => {
        
        dispatch(logout());
        navigate(RoutePathKeys.login);
    };

    return (
        <div style={authorizedLayout as React.CSSProperties}>
            <div>
                <Header style={authorizedMenuStyle as React.CSSProperties}>
                    <div style={{ color: "white", lineHeight: "1.5rem" }}>
                        <div>{userEmail}</div>
                        <div>{userRole}</div>
                    </div>

                    <Avatar
                        src={<UserOutlined />}
                        size={64}
                    />

                    <Tooltip placement="bottom" title="Logout">
                        <Button
                            type="primary"
                            icon={<LogoutOutlined />}
                            onClick={handleLogout}
                        >
                            Logout
                        </Button>
                    </Tooltip>
                </Header>
            </div>
            <Flex>
                <SidenavComponent />
                <ModalProvider>
                    <main className={styles.main}>
                        <Outlet />
                    </main>
                </ModalProvider>
            </Flex>
        </div>
    );
};

export default AuthorizedLayout;

const authorizedLayout = {
    display: "flex",
    flexDirection: "column"
}

const authorizedMenuStyle = {
    display: 'flex',
    alignItems: "center",
    justifyContent: 'flex-end'
}
