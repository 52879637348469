export const enumToOptions = ({
    enumObj,
    allowedValues,
}: {
    enumObj: Record<string, string>;
    allowedValues?: string[];
}) => {
    return Object.entries(enumObj)
        .filter(([key]) => !allowedValues || allowedValues.includes(key))
        .map(([key, value]) => ({
            label: value,
            value: key,
        }));
};