import { useNavigate } from 'react-router';
import { Button, Col, Layout, Row, Typography } from 'antd';

const { Content } = Layout;
const { Title, Paragraph } = Typography;


const HeroComponent = () => {
    const navigate = useNavigate()
    return (
        <Layout>
            <Content style={heroContent}>
                <Row justify="center">
                    <Col span={24}>
                        <Title style={heroTitle}>Welcome to Our Website</Title>
                        <Paragraph style={heroParagraph}>
                            Discover amazing content and explore our features.
                        </Paragraph>
                        <Button onClick={() => navigate("/login")} type="primary" size="large" style={heroButton}>Login</Button>
                    </Col>
                </Row>
            </Content>
        </Layout>
    )
}


export default HeroComponent;


const heroContent = {
    backgroundImage: "url('https://images.pexels.com/photos/586570/pexels-photo-586570.jpeg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white"
}

const heroTitle = {
    color: "white",
    fontSize: "3rem",
    marginBottom: "1rem",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)"
}

const heroParagraph = {
    color: "white",
    fontSize: "1.5re",
    marginBottom: "2rem",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.8)"
}

const heroButton = {
    fontSize: "1.2rem"
}
