import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

interface GanttChartProps {
  startDate: string;
  endDate: string;
};


const GanttChartHeader: React.FC<GanttChartProps> = ({
  startDate,
  endDate,
}) => {

  const svgRef = useRef<SVGSVGElement | null>(null);

  const getMonthsBetween = (start: Date, end: Date) => {

    const months = [];
    let date = new Date(start);
    while (date <= end || date.getMonth() < end.getMonth()) {
      months.push(new Date(date));
      date.setMonth(date.getMonth() + 1);
    }
    return months;
  };

  useEffect(() => {

    if (!svgRef.current) return;

    const start = new Date(startDate);
    const end = new Date(endDate);

    const months = getMonthsBetween(start, end);

    const monthWidth = 50;
    const totalWidth = months.length * monthWidth;
    const height = 50;
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    svg.attr("width", totalWidth);

    svg.selectAll(".month")
      .data(months)
      .enter()
      .append("rect")
      .attr("class", "month")
      .attr("x", (d, i) => i * monthWidth)
      .attr("y", 0)
      .attr("width", monthWidth)
      .attr("height", height)
      .attr("fill", "#00b197")
      .attr("stroke", "black");

    svg.selectAll(".label")
      .data(months)
      .enter()
      .append("text")
      .attr("x", (d, i) => i * monthWidth + 5)
      .attr("y", height / 2)
      .attr("fill", "white")
      .style("font-size", "1rem")
      .each(function (d, i) {
        const textElement = d3.select(this);
        textElement.append("tspan")
          .attr("x", i * monthWidth + 5)
          .attr("dy", 0)
          .text(d3.timeFormat("%y")(d));

        textElement.append("tspan")
          .attr("x", i * monthWidth + 5)
          .attr("dy", "1.2em")
          .text(d3.timeFormat("%b")(d));
      });
  }, [startDate, endDate]);

  return (
    <svg ref={svgRef} height={50}></svg>
  );
};

export default GanttChartHeader;