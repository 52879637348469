import { useLocation, useNavigate, useParams } from "react-router";
import { Menu, MenuProps } from "antd";
import { getCurrentUserRole } from "store/store";
import { useSelector } from "react-redux";
import { MailOutlined, UserOutlined, FundOutlined, TeamOutlined, LeftOutlined } from '@ant-design/icons';

import { navigation } from "shared/constants/Routes";
import { RoutePathKeys } from "shared/constants/RoutePathKeys.enum";
import { RoleDto } from "shared/generated-sources";

const SidenavComponent = () => {
    type MenuItem = Required<MenuProps>['items'][number];

    const navigate = useNavigate();
    const location = useLocation();
    const { id: projectId } = useParams<{ id: string }>();

    const userRole = useSelector(getCurrentUserRole)[0];

    const isProjectPage = location.pathname.startsWith("/projects/") && projectId;
    const isMonthClosingPage = location.pathname.includes("monthClosing");
    const isProjectDirector = (projectId: string): boolean => {
        return true; 
    };

    const children = [];
    if (userRole === RoleDto.name.WORKER || userRole === RoleDto.name.DIRECTOR) {
        children.push({
            key: RoutePathKeys.workerProjects,
            label: 'Worker view'
        });
    }

    if (userRole === RoleDto.name.DIRECTOR) {
        children.push({
            key: RoutePathKeys.directorProjects,
            label: 'Director view'
        });
    }

    if (userRole === RoleDto.name.ADMIN || userRole === RoleDto.name.ACCOUNTANT) {
        children.push({
            key: RoutePathKeys.projects,
            label: 'Projects view'
        });
    }

    const handleMenuClick = ({ key }: { key: string }) => {
        if (key === RoutePathKeys.monthClosing && projectId) {
            navigate(`/projects/monthClosing/${projectId}`);
        } else if (key === 'backToProject' && projectId) {
            navigate(`/projects/${projectId}`);
        } else {
            const { target } = navigation.authorized.find(item => item.key === key) || {};
            if (target) {
                navigate(target);
            }
        }
    };

    const items: MenuItem[] = [
        {
            key: RoutePathKeys.dashboard,
            label: 'Dashboard',
            icon: <MailOutlined />
        },
        {
            key: RoutePathKeys.profile,
            label: 'Profile',
            icon: <UserOutlined />
        },
        ...(userRole !== RoleDto.name.WORKER
            ? [{
                key: RoutePathKeys.users,
                label: 'Users',
                icon: <TeamOutlined />
            }] : []),
        ...(userRole === RoleDto.name.ADMIN || userRole === RoleDto.name.ACCOUNTANT
            ? [{
                key: RoutePathKeys.accountant,
                label: 'Accountant',
                icon: <FundOutlined />
            }]
            : []),
        ...(isProjectPage && (userRole === RoleDto.name.ADMIN || userRole === RoleDto.name.ACCOUNTANT || isProjectDirector(projectId))
            ? isMonthClosingPage
                ? [{
                    key: 'backToProject',
                    label: 'Back to Project',
                    icon: <LeftOutlined />,
                    onClick: () => navigate(`/projects/${projectId}`)
                }]
                : [{
                    key: RoutePathKeys.monthClosing,
                    label: 'Month Closing',
                    icon: <FundOutlined />,
                    onClick: () => navigate(`/projects/monthClosing/${projectId}`)
                }]
            : []),
        {
            key: 'projects',
            label: 'Projects',
            type: 'group',
            children: children,
        },
    ];

    return (
        <Menu
            onClick={handleMenuClick}
            style={{ width: 194 }}
            defaultOpenKeys={['sub1']}
            selectedKeys={[location.pathname]}
            defaultSelectedKeys={[RoutePathKeys.dashboard]}
            mode="inline"
            items={items}
        />
    );
}

export default SidenavComponent;
