export enum TimeUnitEnum{
    Day="Day",
    Week="Week",
    Month="Month",
    Year="Year"
}
export enum Wage_Roles {
    Assistent = 100,
    PhD_Student = 150,
    Engineer = 200,
    PhD = 250,
    Project_Director = 250,
  }