import React from 'react';
import { Form, Input, Select } from 'antd';

import { enumToOptions } from 'shared/constants/enumFunctions';
import { UpdateWorkPackageDto, WorkPackageDto } from 'shared/generated-sources/';

import ModalComponent from 'components/ModalComponent';

interface UpdateWorkPackageModalProps {
    onSubmit: (values: UpdateWorkPackageDto) => void;
    onClose: () => void;
    initialValues: UpdateWorkPackageDto;
}

const UpdateWorkPackageModal: React.FC<UpdateWorkPackageModalProps> = ({ onSubmit, onClose, initialValues }) => {
    return (
        <ModalComponent<UpdateWorkPackageDto>
            open
            name="Update Work Package"
            onClose={onClose}
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please input a name!' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item
                name="description"
                label="Description"
                rules={[{ required: false, message: 'Type in a description' }]}
            >
                <Input type={"string"} />
            </Form.Item>
            <Form.Item

                name="allocatedPersonMonths"
                label="Allocated PM"
                rules={[{ required: true, message: 'Choose PM amount', type: 'number', transform: (value) => (value ? Number(value) : value), }]}
                normalize={(value) => (value ? Number(value) : value)}
            >
                <Input type={"number"} />
            </Form.Item>
            <Form.Item
                name="status"
                hidden
            >   
                <Input type="hidden" />
            </Form.Item >
            <Form.Item
                name="reference"
                label="reference"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>
        </ModalComponent>
    );
};

export default UpdateWorkPackageModal;