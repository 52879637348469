
import { Form, Select } from 'antd';

import { UpdateProjectWorkersDto, UserDto } from 'shared/generated-sources';

import ModalComponent from 'components/ModalComponent';

interface removeWorkersFromProjectModalProps {
    onSubmit: (values:UpdateProjectWorkersDto) => void;
    onClose: () => void;
    workers : UserDto[];
}

const RemoveWorkersFromProjectModal: React.FC<removeWorkersFromProjectModalProps> = ({ onSubmit, onClose, workers }) => {

    const userOptions = workers.map((worker) => ({
        value: worker.id,
        label: `${worker.firstName} ${worker.lastName}`,
    }));

    return (
        <ModalComponent<UpdateProjectWorkersDto>
            open
            name="Remove Workers"
            onClose={onClose}
            onSubmit={onSubmit}
        >
            <Form.Item
                name="usersIds"
                label="Users"
                style={{minHeight:"300px"}}
                rules={[{ required: true, message: 'Search for workers' }]}
            >
                <Select
                    mode="tags"
                    style={{
                        width: '100%',
                    }}
                    dropdownRender={(menu) => (
                        <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {menu}
                        </div>
                    )}
                    tokenSeparators={[',']}
                    options={userOptions}
                />
            </Form.Item>
        </ModalComponent>
    );
};

export default RemoveWorkersFromProjectModal;